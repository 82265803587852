import { 
    GUARDAR_TIPODISPOSITIVO_EXITO,
    GUARDAR_DATACOMPATIBILIDAD_EXITO,
    SET_TIEMPOACTUAL_EXITO,
    SET_DECODED_EXITO
} from '../actionTypes'


const initState = {
    dispositivo: null,
    dataCompatibilidad: null,
    tiempoActual: Math.floor(new Date().getTime()/1000.0),

}

const homeReducer = (state = initState, action) => {
    switch (action.type) {
        case GUARDAR_TIPODISPOSITIVO_EXITO:
            return {
                ...state,
                dispositivo: action.payload,
            }
        case GUARDAR_DATACOMPATIBILIDAD_EXITO:
            return {
                ...state,
                dataCompatibilidad: action.payload,
            }
        case SET_TIEMPOACTUAL_EXITO:
            return {
                ...state,
                tiempoActual: action.payload,
            }
        case SET_DECODED_EXITO:
            return {
                ...state,
                colonias: action.payload,
            }
        default:
        return state
    }
}

export default homeReducer