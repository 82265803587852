import { 
    AGREGAR_PRODUCTO_EXITO,
    STORAGE_CATEGORIASMENU_EXITO,
    STORAGE_ADICIONES_EXITO,
    STORAGE_TELEFONO_EXITO,
    STORAGE_ABIERTO_EXITO,
    STORAGE_COSTOENVIO_EXITO,
    STORAGE_CLIENTEDATA_EXITO,
    SET_ALERTORDEN_EXITO,
} from '../actionTypes'

import uid from 'uid'
import { ViewDaySharp } from '@material-ui/icons';
import { onlyLetters, onlyLettersNumbers, esNumero, metersToKilometers } from '../../allFunctions'
import MapboxDirectionsFactory from '@mapbox/mapbox-sdk/services/directions'

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const directionsClient = MapboxDirectionsFactory({accessToken})

export const buscarDatosComercio = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        await fetch('https://us-central1-comerciosmx-mzt.cloudfunctions.net/comercio_GetData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id_comercio: datos.id_comercio}),
            // body: JSON.stringify({id_comercio: 'P0xh8T3aTvQcytxBY5TrKw0mDun2'}),
            cache: 'no-cache',
        })
        .then(function(response) {
            return response.json()
        })
        .then(function(data) {
            dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: data.categorias })
            dispatch({ type: STORAGE_ADICIONES_EXITO, payload: data.menuAdiciones })
            dispatch({ type: STORAGE_TELEFONO_EXITO, payload: data.telefono })
            dispatch({ type: STORAGE_ABIERTO_EXITO, payload: data.abierto })
            
            const storage = localStorage.getItem('cli-data')
            
            if(storage){
                
                const data = JSON.parse(storage)

                let nombre = ''
                let apellido = ''
                let telefono = ''
                let domicilio = ''
                let referencia = ''
                let idColonia = ''

                Array.prototype.map.call(data.nombre, eachLetter => {
                    
                    if(onlyLetters(eachLetter)){
                        nombre = `${nombre}${eachLetter}`
                    }
                })
                Array.prototype.map.call(data.apellido, eachLetter => {
                    
                    if(onlyLetters(eachLetter)){
                        apellido = `${apellido}${eachLetter}`
                    }
                })
                Array.prototype.map.call(data.telefono, eachLetter => {
                    
                    if(esNumero(eachLetter)){
                        telefono = `${telefono}${eachLetter}`
                    }
                })
                Array.prototype.map.call(data.domicilio, eachLetter => {
                    
                    if(onlyLettersNumbers(eachLetter)){
                        domicilio = `${domicilio}${eachLetter}`
                    }
                })
                Array.prototype.map.call(data.referencia, eachLetter => {
                    
                    if(onlyLettersNumbers(eachLetter)){
                        referencia = `${referencia}${eachLetter}`
                    }
                })

                if(!data.version || data.version !== '2.0'){
                    domicilio = ''
                }else{
                    idColonia = data.idColonia
                }

                const clienteData = {
                    nombre: onlyLetters(nombre) ? nombre : '',
                    apellido: onlyLetters(apellido) ? apellido : '',
                    telefono: esNumero(telefono) ? telefono : '',
                    domicilio: onlyLettersNumbers(domicilio) ? domicilio : '',
                    referencia: onlyLettersNumbers(referencia) ? referencia : '',
                    idColonia: onlyLettersNumbers(idColonia) ? idColonia : '',
                }

                dispatch({ type: STORAGE_CLIENTEDATA_EXITO, payload: clienteData })
            }

        })
        .catch(function(err) {
            console.error(err);
        });

    }
}

export const AgregarProducto = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))
        const data = JSON.parse(JSON.stringify(datos))

        data.id_producto = uid()
        orden.push(data)

        let total = 0

        orden.map(producto => {
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

            producto.adiciones && producto.adiciones.map(adicion => {
                                            
                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    // console.log('costo', ingrediente.costo)
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    return
                })
                return
            })
        })

        dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: orden })
        dispatch({ type: SET_ALERTORDEN_EXITO, alert: true, numProductos: orden.length, total: total })

        resultado.exito = true
        resultado.option = 'reset'
        return resultado
    }
}

export const EditarProducto = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))
        const data = JSON.parse(JSON.stringify(datos))

        orden && orden.map(producto => {
            if(producto.id_producto === data.id_producto){
                producto.cantidad = data.cantidad
                producto.comentario = data.comentario
            }
        })

        let total = 0

        orden.map(producto => {
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precio))
        })

        dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: orden })
        dispatch({ type: SET_ALERTORDEN_EXITO, alert: true, numProductos: orden.length, total: total })

        resultado.exito = true
        resultado.option = 'reset'
        return resultado
    }
}

export const EliminarProducto = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const resultado = {
            exito: false,
            option: '',
            message: '',
            vacio: false
        }

        let orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))
        const data = JSON.parse(JSON.stringify(datos))

        orden = orden.filter(producto => producto.id_producto !== data.id_producto) 

        if(orden.length === 0){
            resultado.exito = true
            resultado.option = 'reset'
            resultado.vacio = true
            dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: [] })
            dispatch({ type: SET_ALERTORDEN_EXITO, alert: false, numProductos: 0, total: 0 })
            return resultado
        }

        orden && orden.map(producto => {
            if(producto.id_producto === data.id_producto){
                producto.cantidad = data.cantidad
                producto.comentario = data.comentario
            }
        })

        let total = 0

        orden.map(producto => {
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precio))
        })

        dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: orden })
        dispatch({ type: SET_ALERTORDEN_EXITO, alert: true, numProductos: orden.length, total: total })

        resultado.exito = true
        resultado.option = 'reset'
        return resultado
    }
}

export const CheckCostoServDomicilio = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: 'reset',
            distancia: null,
            costo: null
        }

        const orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))

        const reqOptions = {
            waypoints: [
                {coordinates: [-106.43859373253285, 23.23774060802562]},
                {coordinates: [datos.longitud, datos.latitud]}
            ],
            profile: 'driving-traffic',
            geometries: 'geojson'
        }

        await directionsClient.getDirections(reqOptions).send()
        .then(response => {
            // console.log('response',response)

            if(response.statusCode){

                const distancia = metersToKilometers(response.body.routes[0].distance)
                
                if(distancia.distancia <= 3){
                    resultado.costo = 40
                }else if(distancia.distancia > 3 && distancia.distancia <= 6){
                    resultado.costo = 45                   
                }else if(distancia.distancia > 6 && distancia.distancia <= 7){
                    resultado.costo = 50                    
                }else if(distancia.distancia > 7 && distancia.distancia <= 8){
                    resultado.costo = 55                    
                }else if(distancia.distancia > 8 && distancia.distancia <= 9){
                    resultado.costo = 80                   
                }else if(distancia.distancia > 9 && distancia.distancia <= 10){
                    resultado.costo = 85                    
                }else if(distancia.distancia > 10 && distancia.distancia <= 11){
                    resultado.costo = 95                    
                }else if(distancia.distancia > 11 && distancia.distancia <= 12){
                    resultado.costo = 105                    
                }else if(distancia.distancia > 12 && distancia.distancia <= 13){
                    resultado.costo = 115                    
                }else if(distancia.distancia > 13 && distancia.distancia <= 14){
                    resultado.costo = 125                    
                }else if(distancia.distancia > 14 && distancia.distancia <= 15){
                    resultado.costo = 135                   
                }else{
                    resultado.costo = 140
                }

                dispatch({ type: STORAGE_COSTOENVIO_EXITO, payload: resultado.costo })

                resultado.distancia = distancia.distanciaMsg
                resultado.exito = true
                // orden.
            }
            
        })
        .catch(error => {
            console.log('error',error)
            
        })

        return resultado
    }
}

export const ValidarEnviarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: true,
            option: 'reset',
            message: '',
            productoAgotado: false,
            type: '',
            producto: '',
            ingrediente: '',
            monto: ''
        }

        const orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))
        let total = 0

        orden.map(producto => {
                            
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

            producto.adiciones && producto.adiciones.map(adicion => {

                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    // console.log('costo', ingrediente.costo)
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    return true
                })
                return true
            })
            
        })

        if(total < 130){
               
            resultado.exito = false
            resultado.type = 'monto'
            resultado.monto = 130
            return resultado
        }

        await fetch('https://us-central1-comerciosmx-mzt.cloudfunctions.net/comercio_GetData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id_comercio: 'q8OdHjmz6qdfaYW5JWeHlxQSbpx2'}),
            // body: JSON.stringify({id_comercio: 'P0xh8T3aTvQcytxBY5TrKw0mDun2'}),
            cache: 'no-cache',
        })
        .then(function(response) {
            return response.json()
        })
        .then(function(data) {

            if(!data.abierto){
                resultado.exito = false
                resultado.type = 'cerrado'
                dispatch({ type: STORAGE_ABIERTO_EXITO, payload: data.abierto })
                return
            }

            const allIngredientesDisabledID = []
            data.menuAdiciones && data.menuAdiciones.map(menuAdicion => {

                menuAdicion.ingredientes && menuAdicion.ingredientes.map(ingrediente => {
                    
                    if(ingrediente.disabled && !allIngredientesDisabledID.includes(ingrediente.id)){
                        allIngredientesDisabledID.push(ingrediente.id)
                    }
                })
            })

            // console.log('allIngredientesDisabledID',allIngredientesDisabledID)
            
            orden.map(producto => {

                total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))


                if(resultado.agotado) return

                data.categorias.map(categoria => {
                    
                    if(resultado.agotado) return

                    categoria.menu.map(menu => {

                        if(resultado.agotado) return

                        if(menu.id_menu === producto.id_menu){
                            // console.log('producto',producto)
                            // console.log('menu',menu)
                            
                            if(menu.disabled){
                                producto.agotado = true
                                resultado.exito = false
                                resultado.type = 'producto'
                                resultado.agotado = true
                                resultado.productoAgotado = producto.nombreMenu
                            }else{
                                producto.agotado = false
                                producto.adiciones && producto.adiciones.map(adicion => {

                                    adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                        // console.log('ingrediente.id',ingrediente)
                                        
                                        total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))

                                        if(allIngredientesDisabledID.includes(ingrediente.id_ingrediente)){
                                            ingrediente.agotado = true
                                            resultado.exito = false
                                            resultado.productoAgotado = true
                                            resultado.type = 'ingrediente'
                                            resultado.producto = producto.nombreMenu
                                            resultado.ingrediente = ingrediente.nombre
                                        }else{
                                            ingrediente.agotado = false
                                        }
                                    })

                                })
                            }
                        }
                        return true
                    })
                    return true
                })
                return true
            })

            dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: orden })
            dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: data.categorias })
            dispatch({ type: STORAGE_ADICIONES_EXITO, payload: data.menuAdiciones })

            return true
        })
        .catch(function(err) {
            console.error(err);
        });



        return resultado
    }
}


export const EnviarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: '',
            texto: ''
        }

        const orden = JSON.parse(JSON.stringify(getState().comercioReducer.orden))
        const costoEnvio = JSON.parse(JSON.stringify(getState().comercioReducer.costoEnvio))
        const data = JSON.parse(JSON.stringify(datos))

        data.nombre = data.nombre.trim()
        data.apellido = data.apellido.trim()
        data.telefono = data.telefono.trim()
        data.domicilio = data.domicilio.trim()
        data.colonia = data.colonia.trim()
        data.referencia = data.referencia.trim()
        data.pagaraCon = data.pagaraCon.trim()

        if(data.nombre === ''){
            resultado.option = 'error'
            resultado.message = 'Proporcione un nombre'
            return resultado
        }

        if(data.apellido === ''){
            resultado.option = 'error'
            resultado.message = 'Proporcione un apellido'
            return resultado
        }

        if(data.telefono === '' || data.telefono.length !== 10){
            resultado.option = 'error'
            resultado.message = 'Proporcione un numero telefonico valido'
            return resultado
        }

        let recoleccion = '*Pasare a recoger*'
        let costoDomicilio = ''
        let total = 0
        let pagaraCon = ''

        if(data.recoleccion === 'domicilio'){

            if(data.domicilio === ''){
                resultado.option = 'error'
                resultado.message = 'Proporcione un domicilio'
                return resultado
            }

            let domicilio = data.domicilio.replace('#', '')
            domicilio = domicilio.replace('&', '')
            domicilio = domicilio.replace('%', '')
            domicilio = domicilio.replace('@', '')
            domicilio = domicilio.replace('*', '')
            domicilio = domicilio.replace('!', '')
            domicilio = domicilio.replace('(', '')
            domicilio = domicilio.replace(')', '')
            domicilio = domicilio.replace('+', '')
            // const domicilio = data.domicilio.replace(/^#+/i, '')

            recoleccion = `Enviar a: *${domicilio} ${data.colonia}*%0AReferencia: *${data.referencia.trim()}*`
            costoDomicilio = `Costo de envio: $ *${parseFloat(costoEnvio).toFixed(1)}*%0A%0A`
            total = parseFloat(costoEnvio)

            if(data.metodoPago === 'efectivo'){
                pagaraCon = `%0AEl cliente pagara con: $ *${parseFloat(data.pagaraCon).toFixed(1)}*`
                
            }else{
                pagaraCon = `%0AEl cliente pagó con Tarjeta%0AID_ORDEN:*${data.id_orden.trim().toUpperCase().slice(-4)}*`

            }
        }

        let name = `*${data.nombre.trim()} ${data.apellido.trim()}*`
        // name = JSON.parse(JSON.stringify(name))
        let nombreProducto = ''
        orden && orden.map(producto => {

            let totalProducto = parseFloat(producto.cantidad) * parseFloat(producto.precioMenu)
            total = parseFloat(total) + parseFloat(totalProducto)
            // const cantidad = `*${producto.cantidad}*`
            let ingredientesTitulo = ''
            producto.adiciones && producto.adiciones.map(adicion => {

                if(adicion.menuAdiciones && adicion.menuAdiciones.length === 0) return null

                let titulo = `${adicion.titulo.trim()}`
                let ingredientes = ''
                                        
                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    // console.log('costo', ingrediente.costo)
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    totalProducto = parseFloat(totalProducto) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))

                    if(parseFloat(ingrediente.costo) !== 0){
                        ingredientes = `${ingredientes}- _*${ingrediente.nombre}*_ _*${parseFloat(ingrediente.costo).toFixed(1)}*_ %0A`
                    }else{
                        ingredientes = `${ingredientes}- _*${ingrediente.nombre}*_ %0A`
                    }

                    return
                })

                ingredientesTitulo = `${ingredientesTitulo}${titulo}%0A${ingredientes}`
                return
            })

            if(producto.comentario.trim() === ''){
                nombreProducto = `${nombreProducto}*${producto.cantidad}x* - *${producto.nombreMenu}* $ *${parseFloat(totalProducto).toFixed(1)}*%0A ${ingredientesTitulo}`
            }else{
                nombreProducto = `${nombreProducto}*${producto.cantidad}x* - *${producto.nombreMenu}* $ *${parseFloat(totalProducto).toFixed(1)}*%0A ${ingredientesTitulo}Comentario:%0A _*${producto.comentario.trim()}*_%0A`
            }
        })

        let comisionTarjetaText = ''
        if(data.metodoPago === 'tarjeta' && data.recoleccion === 'domicilio'){

            let comisionTarjeta = (( parseFloat(total) * 0.036) + 3)
            const iva = parseFloat(comisionTarjeta) * 0.16
            comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
            comisionTarjetaText = `Comision pago con tarjeta: $ *${parseFloat(comisionTarjeta).toFixed(1)}*%0A%0A`
            total = parseFloat(total) + parseFloat(comisionTarjeta)
        }

        resultado.exito = true
        resultado.option = 'reset'
        resultado.texto = `Hola soy ${name}%0A
mi teléfono es%3A ${data.telefono}%0A
Quiero realizar el siguiente pedido%3A%0A%0A
${nombreProducto}%0A
${costoDomicilio}
${comisionTarjetaText}
${recoleccion}%0A%0A
Total: $ *${parseFloat(total).toFixed(1)}*
${pagaraCon}
        `
        delete data.recoleccion  
        localStorage.setItem('cli-data', JSON.stringify(data))
        return resultado

    }
}

export const CreatePaymentIntent = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: '',
            paymentIntent: null
        }

        // console.log('datos payment',datos)
        
        // return resultado

        const firebase = getFirebase()
        const funct = firebase.functions()
        const Cliente_CreatePaymentIntent = funct.httpsCallable('Cliente_CreatePaymentIntent')
        await Cliente_CreatePaymentIntent(datos)
        .then(response => {

            console.log('response',response)

            if(response.data.exito){
                resultado.exito = response.data.exito
                resultado.paymentIntent = response.data.paymentIntent
            }

        })
        .catch(error => {
            console.log('error', error);
        })

        return resultado
    
    }
}


export const FinalizarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: AGREGAR_PRODUCTO_EXITO, payload: [] })
        dispatch({ type: SET_ALERTORDEN_EXITO, alert: false, numProductos: 0, total: 0 })

    }
}


export const PayMercadoPago = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: '',
            texto: ''
        }

        const firebase = getFirebase()
        const funct = firebase.functions()
        const mercadoPago_payment = funct.httpsCallable('mercadoPago_payment')
        await mercadoPago_payment(datos)
        .then(response => {


            // console.log('response', response);
            // console.log('response.data', response.data);

            if(response){

                if(response.hasOwnProperty('data') && typeof(response.data) === 'object' &&
                    response.data.hasOwnProperty('status') && response.data.status === 201 &&
                    response.data.hasOwnProperty('body') && typeof(response.data.body) === 'object' &&
                    response.data.body.hasOwnProperty('status') && typeof(response.data.body.status) === 'string' && response.data.body.status === 'approved' &&
                    response.data.body.hasOwnProperty('status_detail') && typeof(response.data.body.status_detail) === 'string' && response.data.body.status_detail === 'accredited'
                ){
                    resultado.exito = true
                    resultado.option = 'reset'

                }else{

                    if(response.hasOwnProperty('data') && typeof(response.data) === 'object' &&
                        response.data.hasOwnProperty('cause') && typeof(response.data.cause) === 'object' && response.data.cause.length !== 0 &&
                        response.data.hasOwnProperty('status') && response.data.status === 400
                    ){

                        const code = response.data.cause[0].code.toString()
                        resultado.option = 'preve'
                        resultado.message = 'No se pudo acreditar el pago'

                    }else{
                        resultado.option = 'preve'
                        resultado.message = 'No se pudo acreditar el pago'
                    }

                }

            } 
                
        })
        .catch(error => {
            console.log('error', error);
        })

        return resultado
    }
}