import React, {Fragment } from 'react'
import { connect } from 'react-redux'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

//*COMPONENTES
import CheckoutForm from "./CheckoutForm"

//*MATERIAL UI

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})
const promise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const PaymentCard = (props) => {


    const { total, setOpenPagoTarjeta, nombre, apellido, telefono, domicilio, referencia, handlePaymentResult } = props
    return (
        <Fragment>
            <div className="App">
                <Elements stripe={promise}>
                    <CheckoutForm 
                    total={total} 
                    setOpenPagoTarjeta={setOpenPagoTarjeta}
                    nombre={nombre}
                    apellido={apellido}
                    telefono={telefono}
                    domicilio={domicilio}
                    referencia={referencia}
                    handlePaymentResult={handlePaymentResult}
                    />
                </Elements>
            </div>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentCard))

PaymentCard.propTypes = {
    
}