import { 
    GUARDAR_TIPODISPOSITIVO_EXITO,
    GUARDAR_DATACOMPATIBILIDAD_EXITO,
    SET_TIEMPOACTUAL_EXITO,
    SET_DECODED_EXITO
} from '../actionTypes'


const resultadoCompatibiliad = (datosCompatibilidad) => {

    const data = datosCompatibilidad

    const fails = {
        osFail: false,
        isTablet: false,
        browserFail: false,
        fail: false
    }

    if(data.osName !== 'Android' && data.osName !== 'iPhone'){
        fails.osFail = true
        fails.fail = true
    }
    
    if(data.osName === 'Android'){
        // && data.browserName !== 'Samsung' 
        if(data.browserName !== 'Chrome'){
            fails.browserFail = true
            fails.fail = true
        }

        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(window.userAgent)
        
        if(isTablet){
            fails.isTablet = true
            fails.fail = true
        }
    }

    if(data.osName === 'iPhone'){

        if(data.browserName !== 'Safari'){
            fails.browserFail = true
            fails.fail = true
        }
    }

    return fails

}

export const VerificarCompatibilidad = (data) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' },
            ],
            databrowser: [
                { name: 'Samsung', value: 'SamsungBrowser', version: 'SamsungBrowser' },
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
            ],
            init: function () {
                var agent = this.header.join(' '),
                    os = this.matchItem(agent, this.dataos),
                    browser = this.matchItem(agent, this.databrowser);
                
                return { os: os, browser: browser };
            },
            matchItem: function (string, data) {
                var i = 0,
                    j = 0,
                    html = '',
                    regex,
                    regexv,
                    match,
                    matches,
                    version;
                
                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
    
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        }

        function isFacebookApp() {
            var ua = navigator.userAgent || navigator.vendor || window.opera;
            return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
        }

        // console.log('isFacebookApp', isFacebookApp());
    
        const data = module.init()
        const datosCompatibilidad = {
            osName: data.os.name,
            osVersion: data.os.version,
            browserName: data.browser.name,
            browserVersion: data.browser.version,
            userAgent: navigator.userAgent,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
            vendor: navigator.vendor,
            isFacebookApp: isFacebookApp()
        }

        let dispositivo = 'phone'

        if(datosCompatibilidad.osName === 'Windows' || datosCompatibilidad.osName === 'Macintosh' || datosCompatibilidad.osName === 'Linux'){
            dispositivo = 'pc'
        }
        
        datosCompatibilidad.fails =  resultadoCompatibiliad(datosCompatibilidad)
        dispatch({ type: GUARDAR_TIPODISPOSITIVO_EXITO, payload: dispositivo })
        dispatch({ type: GUARDAR_DATACOMPATIBILIDAD_EXITO, payload: datosCompatibilidad })
    }
}

export const SetDecoded = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: SET_DECODED_EXITO, payload:  datos })

    }
}

export const TiempoActual = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const progress = () => {

            dispatch({ type: SET_TIEMPOACTUAL_EXITO, payload:  Math.floor(new Date().getTime()/1000.0) })
        }

        let timerID = null
        timerID = setInterval(progress, 1000)
    }
}
