import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter} from 'react-router-dom'
import { loadReCaptcha } from 'react-recaptcha-v3'

import Principal from './componentes/principal/Principal'
import Comercio from './componentes/comercio/Comercio'
import UluaPokeHouse from './componentes/whats/UluaPokeHouse'
import ProtectedRouteLocalizador from './componentes/auth/ProtectedRouteLocalizador'
import ProtectedRouteLogout from './componentes/auth/ProtectedRouteLogout'

import Navbar from './componentes/layaout/navegacion/Navbar'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

///MUI colores para el theme
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: '#515b5f',
      main: '#263238',
      // dark: '#1a2327',
      contrastText: '#fff',
    },
    secondary: {
      // light: '#33c9dc',
      main: '#00BCD4',
      // dark: '#008394',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto Condensed", sans-serif',
    subtitle1: {
      fontSize: 11,
      fontWeight: 400,
    },
    boldxx: {

    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 400,
    },
    body1: {
      fontSize: 13,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    h6: {
      fontSize: 15,
      fontWeight: 400,
    },
    h5: {
      fontSize: 17,
      fontWeight: 400,
    },
    h4: {
      fontSize: 19,
      fontWeight: 400,
    },
    h3: {
      fontSize: 21,
      fontWeight: 400,
    },
    h2: {
      fontSize: 23,
      fontWeight: 400,
    },
    h1: {
      fontSize: 25,
      fontWeight: 400,
    },
    initial: {
      color: '#00BCD4'
    }
  },
});

// window.Mercadopago.setPublishableKey("TEST-17e72890-ad59-44d3-8220-00775bb77d42");

function App() {

  useEffect(() => {


    // Actualiza el título del documento usando la API del navegador
    // loadReCaptcha('6Ld7gsQUAAAAAMIgSEWkhT-Whtrx9OxVu3Cnsurm')

  },[])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {/* <Navbar />                 */}
            <Switch>
              {/* <Route exact path='/' component={Principal} /> */}
              {/* <Route exact path='/comercio' component={Comercio} /> */}
              <Route exact path='/' component={UluaPokeHouse} />
            </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App;
