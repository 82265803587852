import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'

class ProtectedRouteLocalizador extends Component {
    
    render() {
        const {profile, auth, component: Component, ...rest} = this.props


        if(auth.uid){
            // console.log(auth.uid)
            if(!profile.perfil) return null
                
            // console.log('esta logueado 1')
            if(profile.perfil === 'localizador'){

                // console.log('no esta logueado')
                
                return(
                    <Route {...rest} component={Component} />
                )
                
            }else{
                // console.log('redirect a /')
                return(
                    <Redirect to='/' />
                )
            }    
           
        }else{ 
            // console.log('esta logueado comercio')
                return(           
                    <Redirect to='/' />
                )
            
        }

       
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ProtectedRouteLocalizador)