import authReducer from './authReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import homeReducer from './homeReducer'
import comercioReducer from './comercioReducer'
import messageReducer from './messageReducer'

const rootReducer = combineReducers({
    comercioReducer: comercioReducer,
    homeReducer: homeReducer,
    messageReducer: messageReducer,
    loginUser: authReducer,    
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer