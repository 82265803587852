import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions'
import 'firebase/storage'

//configurar firestore
let firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
    // appId: process.env.REACT_APP_APP_ID,
    // measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

//inicializar firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.storage()
firebase.functions()

// if(process.env.NODE_ENV === 'development'){
    
//     firebase.auth().useEmulator('http://localhost:9099')
//     firebase.firestore().useEmulator('localhost', 8080)
//     firebase.functions().useEmulator('localhost', 5001)
//     firebase.storage().useEmulator('localhost', 9199)
// }

export default firebase