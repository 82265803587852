import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*COMPONENTES
import { EnviarOrden } from '../../../store/actions/comercioActions'
import { FinalizarOrden } from '../../../store/actions/comercioActions'
import { SendMenssageAction } from '../../../store/actions/messageActions'
import { telFormat } from '../../../allFunctions'
//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from "@material-ui/core/Typography"
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import CardMedia from '@material-ui/core/CardMedia'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 900,
        } 
    },
    media: {
        height: 70,
        width: 70,
    },
    cerrar: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            width: 0,
            minWidth: 900,
        } 
    },
})

const PagoExitoso = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    const handleEnviarOrden = async () => {

        props.SendMenssageAction({option: 'inicio'})
        
        const enviar = await props.EnviarOrden(props.dataPagoExitoso)

        if(enviar.exito){
            const orden = `https://api.whatsapp.com/send?text=${enviar.texto}&phone=+52${props.telefonoComercio}`

            window.open(orden, '_blank');
        }
        props.SendMenssageAction(enviar)

    }

    const handleChangeCalcularComisionTarjeta = () => {

        let costoDomicilio = 0
        if(props.dataPagoExitoso.recoleccion === 'domicilio'){
            costoDomicilio = props.costoEnvio
        }
        let total = parseFloat(costoDomicilio)

        props.orden.map(producto => {
                        
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

            producto.adiciones && producto.adiciones.map(adicion => {

                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    // console.log('costo', ingrediente.costo)
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    return
                })
                return
            })
            return
        })

        let comisionTarjeta = (( parseFloat(total) * 0.036) + 3)
        const iva = parseFloat(comisionTarjeta) * 0.16
        comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
        
        // setComisionTarjeta(comisionTarjeta)
        return comisionTarjeta

    }

    const handleClosePagoExitoso = () => {

        props.setOpenPagoExitoso(false)
        props.FinalizarOrden()
    }


    //*render
    if(!props.dataPagoExitoso) return null
    const { classes, openPagoExitoso, orden, costoEnvio, dataPagoExitoso } = props

    // const { classes, openPagoExitoso, orden, costoEnvio } = props
    // const dataPagoExitoso = {
    //     "recoleccion": "domicilio",
    //     "nombre": "Daniel",
    //     "apellido": "Rincon",
    //     "telefono": "6699948312",
    //     "domicilio": "Poniente 45B Fracc. del bosque",
    //     "referencia": "",
    //     "metodoPago": "tarjeta",
    //     "id_orden": "z5ymc4jpsk9",
    //     "pagaraCon": ""
    // }

    console.log('props exitoso',props)
    
    let costoDomicilio = 0
    let comisionTarjeta = 0
    let total = 0
    if(dataPagoExitoso.recoleccion === 'domicilio'){
        costoDomicilio = costoEnvio
        total = parseFloat(total) + parseFloat(costoDomicilio)
    }
    if(dataPagoExitoso.metodoPago === 'tarjeta'){
        comisionTarjeta = handleChangeCalcularComisionTarjeta()
        total = parseFloat(total) + parseFloat(comisionTarjeta)
    }
    return (
        <Fragment>
            <Dialog 
            open={openPagoExitoso}
            // TransitionComponent={Transition}
            fullScreen
            PaperProps={{
                style: {
                  backgroundColor: "#eff2f3",
                  boxShadow: "none"
                }
              }}
            >
            
            <Grid item className={classes.styleContenedor} xs={12}  >
            <Box>

                <Box mt={5}>
                    
                    <Box mb={1} align='center'>
                        <CardMedia
                            className={classes.media}
                            image={`${process.env.PUBLIC_URL}/ulua-logo.jpg`}
                            style={{borderRadius: '50%'}}
                        />
                    </Box>

                    <Box align='center'>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 22, color: '#80cbc4'}}>
                            ¡PAGO EXITOSO!
                        </Typography>
                    </Box>

                    <Box px={2} mb={3}>
                        <Button startIcon={<WhatsAppIcon fontSize='large'/>}  variant="contained" color="secondary" style={{height: 54}} fullWidth onClick={handleEnviarOrden}>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, color: '#fff'}}>
                            Enviar orden
                            </Typography>
                        </Button>
                    </Box>

                    <Box mx={1}>
                    <Card variant='outlined'>
                        <Typography style={{fontFamily: 'Montserrat', fontSize: 14, textAlign: 'center', borderRadius: 4, backgroundColor: '#000', color: '#fff'}}>
                        <b>Orden ID:</b> <b style={{color: 'cyan'}}>{dataPagoExitoso.id_orden.toUpperCase().slice(-4)}</b>
                        </Typography>
                        {orden && orden.map(producto => {
                            
                            let totalProducto = parseFloat(producto.precioMenu) * parseFloat(producto.cantidad)
                            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

                            producto.adiciones && producto.adiciones.map(adicion => {

                                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                    // console.log('costo', ingrediente.costo)
                                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                    totalProducto = parseFloat(totalProducto) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                    return
                                })
                                return
                            })
                            
                            return (

                                <Box px={1} key={producto.id_producto} >
                                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                                        {producto.cantidad} x - {producto.nombreMenu}
                                    </Typography>
                                </Box>
                            )
                        })}

                        <Box px={1} pt={2} style={{minWidth: '80px'}}>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                            Total: $ {parseFloat(total).toFixed(2)}
                            </Typography>
                        </Box>
                    </Card>
                    </Box>


                    <Box mt={2} mx={1} align='center'>
                        <Card variant='outlined'>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, textAlign: 'center', borderRadius: 4, backgroundColor: '#000', color: '#fff'}}>
                            Datos del Cliente
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                            {dataPagoExitoso.nombre} {dataPagoExitoso.apellido}
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                            Tel:{telFormat(dataPagoExitoso.telefono)}
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                            {dataPagoExitoso.domicilio}
                            </Typography>
                        </Card>
                    </Box>

                </Box>

                <Box mb={9}>

                </Box>

                <Box boxShadow={3} className={classes.cerrar}>
                    <Box>
                        <Button variant="contained" color="primary" fullWidth style={{height: 54}} onClick={handleClosePagoExitoso}>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 18}}>
                            CERRAR
                            </Typography>
                        </Button>
                    </Box>
                </Box>

            </Box>
            </Grid>

            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        orden: state.comercioReducer.orden,
        costoEnvio: state.comercioReducer.costoEnvio,
        telefonoComercio: state.comercioReducer.telefonoComercio,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         EnviarOrden: (datos) => dispatch(EnviarOrden(datos)),
         FinalizarOrden: (datos) => dispatch(FinalizarOrden(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PagoExitoso))

PagoExitoso.propTypes = {
    
}