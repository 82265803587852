import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography"

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    title: {
        backgroundColor: '#bcaaa4'
    }
})


const RadioOpt = (props) => {

    const [state, setState] = useState({ingredientes: []})
    const [maximo, setMaximo] = useState(0)
    const [value, setValue] = React.useState('');

    // useEffect(() => {

    //     console.log('adiciones', adiciones);
    //     setState({ingredientes: adiciones.ingredientes})
    //     setMaximo(adiciones.maximo)

    // }, [])

    useEffect(() => {

        if(props.openCantidad){
            const adiciones = JSON.parse(JSON.stringify( props.menuAdicion))

            setState({ingredientes: adiciones.ingredientes})
            setMaximo(adiciones.maximo)
        }else{
            setState({ingredientes: []})
            setMaximo(0)
        }

    }, [props.openCantidad])

    const handleChange = (event) => {

        setValue(event.target.value);

        let ingredientes = state.ingredientes
        let addAdicion
        ingredientes.forEach(ingrediente => {
            if (ingrediente.id === event.target.value){

                addAdicion = {
                    id_adicion: props.menuAdicion.id_adicion,
                    id_ingrediente: ingrediente.id,
                    nombre: ingrediente.nombre,
                    costo: ingrediente.costo,
                    titulo: props.menuAdicion.titulo,
                    type: props.menuAdicion.type,
                    agotado: false
                }
            }
        })

        props.handleAddAdicion(addAdicion)
    }
    
    //*render
    if(!state.ingredientes && state.ingredientes.length === 0) return null

    const { classes, menuAdicion, adicionError } = props
    return (
        <div id={menuAdicion.id_adicion}>
            <Box p={1} align='center' className={classes.title} style={{backgroundColor: adicionError ===  menuAdicion.id_adicion ? '#c62828' : '', color: adicionError ===  menuAdicion.id_adicion ? '#fff' : ''}}>
                <Typography variant='h6'>{menuAdicion.titulo}</Typography>
            </Box>
            <Box px={2}>
                <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        {state.ingredientes.map(ingrediente => {
                            
                            const costo = <Typography variant='h6' style={{color: ingrediente.nombre.includes("Sin ") ? '#c62828' : ''}}>
                                {ingrediente.nombre} <b>{ingrediente.costo !==0 ? ` + $ ${parseFloat(ingrediente.costo).toFixed(2)}` : ''}</b> <b>{ingrediente.disabled ? '- AGOTADO' : ''}</b>
                                </Typography>
                            return(
                                <FormControlLabel key={ingrediente.id} disabled={ingrediente.disabled} value={ingrediente.id} control={<Radio />} label={costo} />
                            )
                        })}
                        {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
                    </RadioGroup>
                </FormControl>
            </Box>
        </div>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RadioOpt))