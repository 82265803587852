import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
// import CheckOpt from './CheckOpt'
// import RadioOpt from './RadioOpt'
import { EditarProducto } from '../../store/actions/comercioActions'
import { SendMenssageAction } from '../../store/actions/messageActions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CardMedia from '@material-ui/core/CardMedia';
import Drawer from '@material-ui/core/Drawer'
import TextField from '@material-ui/core/TextField'
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'


import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    disabled: {
        color: '#e57373'
    },
    titleMenu: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    moreLess: {
        backgroundColor: '#e0e0e0',
        '&:hover': {
        backgroundColor: "#e0e0e0",
        },
    },
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 999
    },
    title: {
        backgroundColor: '#bcaaa4'
    }
})


const MenuDetalle = (props) => {

    const [menu, setMenu] = useState(null)

    useEffect(() => {

        if(props.openCantidad){

            setMenu({
                id_producto: props.menu.id_producto,
                id_menu: props.menu.id_menu,
                nombre: props.menu.nombre,
                descripcion: props.menu.descripcion,
                precio: props.menu.precio,
                cantidad: props.menu.cantidad,
                comentario: props.menu.comentario,
            })

        }else{
            setMenu(null)
        }

    }, [props.menu, props.openCantidad])

    const add = () => {
        setMenu({
            ...menu,
            cantidad: menu.cantidad + 1
        })        
    }

    const remove = () => {
        if(menu.cantidad > 1){
            setMenu({
                ...menu,
                cantidad: menu.cantidad - 1
            })
        }        
    }    

    const handleChange = (e) => {
        
        setMenu({
            ...menu,
            [e.target.name] : e.target.value.toLowerCase()
        })
    }

    const handleEditarProducto = async () => {
        props.SendMenssageAction({option: 'inicio'})
        const editar = await props.EditarProducto(menu)
        if(editar.exito){
            props.setOpenCantidad(false)
        }
        props.SendMenssageAction(editar)
    }
    

    //*render
    if(!menu) return null
    const { classes, openCantidad, setOpenCantidad } = props
    // let nombreAdicion = ''

    // menu.adiciones && menu.adiciones.map(adicion => {
    //     nombreAdicion = `${nombreAdicion} ${adicion.nombre} - `
    // })

    return (
        <Fragment>
             {/* <Drawer anchor={'top'} open={openCantidad} onClose={() => setOpenCantidad(false)} > */}
             <Dialog 
            open={openCantidad}
            // TransitionComponent={Transition}
            fullScreen
            >
                <Box p={1} display="flex" alignItems="start" css={{ minHeight: 25, maxHeight: 50 }} className={classes.fixedUp} >
                    <Box mx={1} onClick={() => setOpenCantidad(false)}>
                        <Box display="flex" alignItems="center" css={{ minHeight: 25, maxHeight: 50 }}>
                            <Box>
                                <ArrowBackIcon />
                            </Box>
                        </Box>
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h5" justify="center" >
                        {menu.nombre}
                        </Typography>
                    </Box>
                    <Box mx={2} minWidth={70} >
                        <Box display="flex" alignItems="center" css={{ minHeight: 25, maxHeight: 50 }}>
                            <Typography variant="h4" >
                                $ {parseFloat(menu.precio).toFixed(2)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box mt={7}>
                    <Card className={classes.card} elevation={0}>
                        <Box py={0.5} px={1}>
                            <Typography variant="h6" color="textSecondary" align={'justify'} >
                                {menu.descripcion}
                            </Typography>
                        </Box>

                        <Box mt={1} display="flex" justifyContent="center" bgcolor="background.paper" >
                            <Box >
                                <IconButton className={classes.moreLess} color="primary" size="medium" onClick={remove}>
                                    <RemoveIcon fontSize="inherit" />
                                </IconButton>                            
                            </Box>
                            <Box >
                                <IconButton className={classes.margin} size="medium">
                                    {menu.cantidad}
                                </IconButton>
                            </Box>
                            <Box >
                                <IconButton className={classes.moreLess} size="medium" onClick={add}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box mb={1} px={1}>
                            <TextField
                            style={{fontFamily: 'Montserrat', fontSize: 14}}
                            label={
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                ¿Algun comentario?
                                </Typography>
                            }
                            inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                            name="comentario"
                            value={menu.comentario}
                            onChange={handleChange}
                            color="secondary"
                            multiline
                            rowsMax="2"
                            fullWidth
                        />
                        </Box>
                        <Box mb={1} display="flex" height={54} alignItems="center" justifyContent="center" >
                            <Box px={2} flexGrow={1}>
                                <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 18}}>
                                Total: ${(menu.precio * menu.cantidad).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box pr={1}>
                                <Button variant="contained" color="secondary" style={{height: 54}} onClick={handleEditarProducto}>
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>  
                    </Card>
                </Box>

                </Dialog>
            {/* </Drawer> */}
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         EditarProducto: (datos) => dispatch(EditarProducto(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuDetalle))

MenuDetalle.propTypes = {
    
}