import { ENVIAR_MESSAGE_INICIO,
    ENVIAR_MESSAGE_PREVE,
    ENVIAR_MESSAGE_EXITO,
    ENVIAR_MESSAGE_ERROR,
    RESET_STATE_ENVIARMESSAGE } from '../actionTypes'


export const ResetMessage = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({ type: RESET_STATE_ENVIARMESSAGE })
    }
}

export const LoadingScreen = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: ENVIAR_MESSAGE_INICIO })
    }
}

export const ResetLoadingMenssage = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: RESET_STATE_ENVIARMESSAGE })
    }
}

export const SendMenssageAction = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        
        if(datos.option === 'inicio'){
            dispatch({ type: ENVIAR_MESSAGE_INICIO })
        }

        setTimeout(() => {
            
            switch (datos.option) {
                case 'preve':
                    dispatch({ type: ENVIAR_MESSAGE_PREVE, payload: datos.message })               
                    break
                case 'exito':
                    dispatch({ type: ENVIAR_MESSAGE_EXITO, payload: datos.message })
                    break
                case 'error':
                    dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: datos.message })
                    break
                case 'reset':
                    dispatch({ type: RESET_STATE_ENVIARMESSAGE })
                    break
                
                default:
                    return null
            }

        }, 300)

    }
}


