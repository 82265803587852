import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'

class ProtectedRouteLogout extends Component {
   
    render() {
        const {profile, auth, component: Component, ...rest} = this.props


        if(auth.uid){
            if(!profile.perfil) return null
                
            // console.log('esta logueado 1')
            if(profile.perfil === 'localizador'){
                // console.log('esta logueado super')    
                return(
                    <Redirect to='/comercios/localizar' />
                )
    
            }else if(profile.perfilCuenta === 'comercio'){
                // console.log('esta logueado comercio')
                return(           
                    <Redirect to='/comercio/panel' />
                )
            }else{
                // console.log('redirect a /')
                return(
                    <Redirect to='/' />
                )
            }    
           
        }else{ 
            
           // console.log('no esta logueado')
            return(
                <Route {...rest} component={Component} />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ProtectedRouteLogout)
