export const validarLoginVacio = (datos) => {
   
    let error = false
    let mensajeError = ''

    if(datos.email === '' || datos.password === ''){
        error = true
        mensajeError =  'Todos los campos son obligatiros'
    }else if(!validarCorreo(datos.email)){//
        error = true
        mensajeError = 'El formato del correo es invalido'
    }else if(datos.password.length < 6){
        error = true
        mensajeError = 'La contraseña debe contener al menos 6 digitos'
    }

    const validacion = {
        error,
        mensajeError
    }
    
    return validacion
}

function inserta(main_string, ins_string, pos) {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }

   const main = main_string.substr(ins_string.length, ins_string.length)
    return main + main_string.slice(0, pos) + ins_string + main_string.slice(pos) + ins_string
}

function insertb(main_string, ins_string, pos) {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }
   const main = main_string.substr(ins_string.length)
   const resultado = (main.substr(0,pos) + (main.substr(pos)).substr(pos)).substr(0, ((main.substr(0,pos) + (main.substr(pos)).substr(pos)).length - pos))
    return resultado
}

export const esNumero = (num) => {

       if (/^\d+$/.test(num) || num === '') {
        return true
    }
    return false
}

export const validarCorreo = (correo) => {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(correo.match(regEx)){
        return true // el correo es correcto
    }
    return false
}

export const telFormat = (telefono) => {

    const input = telefono.replace(/\D/g,'').substring(0,10);
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    let tel = ''
    if(input.length > 6){
        tel = `(${zip}) ${middle} - ${last}`
    }else if(input.length > 3){
        tel = `(${zip}) ${middle}`
    }else if(input.length > 0){
        tel = `(${zip}`
    }

    return tel

}

export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export const getFechaHoy = () => {

    var date = new Date()
    const ArraydiasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
    return ArraydiasSemana[date.getDay()]
}

export const getFecha = (seconds, option) => {

    var date = new Date( seconds * 1000);

    const arrayMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    const ArraydiasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
    // const fecha=new Date();
    // console.log(`fecha ${fecha}`, typeof(fecha));
    // console.log(`data ${data}`, typeof(data));
    if(option === 1){
        return `${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.getFullYear()}`
    }else if(option === 2){
        return `${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
    }else if(option === 3){
        return `${ArraydiasSemana[date.getDay()]} ${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
    }
    // console.log(`${ArraydiasSemana[fecha.getDay()]} ${fecha.getDate()} ${arrayMeses[fecha.getMonth()]} ${fecha.getFullYear()} ${fecha.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`);

    return null
}

export const fisrtCapitalLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const onlyLetters = (data) => {

    const xprex = /^[A-Za-z\s]*$/;

    if(data.match(xprex)){

        return true
    }
    return false
}

export const onlyLettersNumbers = (data) => {

    const xprex = /^[0-9a-zA-Z\s\.\,]*$/;

    if(data.match(xprex)){

        return true
    }
    return false
}

export const ordenarRight = ( str ) => {

    try {
        // return btoa(atob(str)) == str;
        // console.log(str);
        const result = insertb(str,'WEim9zcOiJEZEIiWwgQm9',21)
        // console.log('result', result)
        return JSON.parse(decodeURIComponent(escape(window.atob( result ))))
        return JSON.parse(str )
    } catch (err) {
        console.log(err);
        return false;
    }
}

export const metersToKilometers = (meters) => {

    let km = meters / 1000

    if(km <= 1){
        return {distanciaMsg: `${parseFloat(km).toFixed(1)} mts.`, distancia: km}
    }else{
        return {distanciaMsg: `${parseFloat(km).toFixed(1)} km.`, distancia: km}
    }
}

export const ordenarLeft = ( str ) => {

    try {

        const string = window.btoa(unescape(encodeURIComponent( JSON.stringify(str ))))
        const result = inserta(string,'WEim9zcOiJEZEIiWwgQm9',21)
        // console.log('result', result)
        
        return result
        // return JSON.stringify(str )
    }
    catch(error) {
        console.error('error uri', error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
    }

    
}