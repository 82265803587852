import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'


//*MATERIAL UI
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box'
import Typography from "@material-ui/core/Typography"

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    title: {
        backgroundColor: '#bcaaa4'
    }
})

const CheckOpt = (props) => {

    const [state, setState] = useState({ingredientes: []})
    const [maximo, setMaximo] = useState(0)

    useEffect(() => {

        if(props.openCantidad){
            const adiciones = JSON.parse(JSON.stringify(props.menuAdicion))

            setState({ingredientes: adiciones.ingredientes})
            setMaximo(adiciones.maximo)
        }else{
            setState({ingredientes: []})
            setMaximo(0)
        }

    }, [props.openCantidad])

    const handleChange = (event) => {

        let ingredientes = state.ingredientes
        let count = 0

        ingredientes.forEach(ingrediente => {

            if(ingrediente.isChecked){
                count = count + 1
            }
        })

        if(count < maximo || !event.target.checked) {

            let addAdicion
            ingredientes.forEach(ingrediente => {
                if (ingrediente.id === event.target.name){

                    ingrediente.isChecked =  event.target.checked
                    addAdicion = {
                        id_adicion: props.menuAdicion.id_adicion,
                        id_ingrediente: ingrediente.id,
                        nombre: ingrediente.nombre,
                        costo: ingrediente.costo,
                        titulo: props.menuAdicion.titulo,
                        type: props.menuAdicion.type,
                        agotado: false
                    }
                }
            })

            if(event.target.checked){
                props.handleAddAdicion(addAdicion)
            }else{
                props.handleRemoveAdcion(addAdicion)
            }
            
            setState({ingredientes: ingredientes})
        }

    };

    //*render
    if(state.ingredientes.length === 0) return null
    const { classes, menuAdicion, adicionError } = props

    return (
        <div id={menuAdicion.id_adicion}>
                <Box p={1} align='center' className={classes.title} style={{backgroundColor: adicionError ===  menuAdicion.id_adicion ? '#c62828' : '', color: adicionError ===  menuAdicion.id_adicion ? '#fff' : ''}}>
                    <Typography variant='h6'>{menuAdicion.titulo}</Typography>
                </Box>

                <Box px={2}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                        {state.ingredientes.map(ingrediente => {
                            const costo = <Typography variant='h6' style={{color: ingrediente.nombre.includes("Sin ") ? '#c62828' : ''}}>
                            {ingrediente.nombre} <b>{ingrediente.costo !==0 ? ` + $ ${parseFloat(ingrediente.costo).toFixed(2)}` : ''}</b> <b>{ingrediente.disabled ? '- AGOTADO' : ''}</b>
                            </Typography>
                            return(
                                <FormControlLabel
                                    control={<Checkbox checked={ingrediente.isChecked} onChange={handleChange} name={ingrediente.id} />}
                                    label={costo}
                                    key={ingrediente.id}
                                    disabled={ingrediente.disabled}
                                />
                            )
                        })}
                        </FormGroup>
                    </FormControl>
                </Box>
        </div>
    )
}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckOpt))