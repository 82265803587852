import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import uid from 'uid'
//*COMPONENTES
// import MenusOrden from './MenusOrden'
// import { CancelarOrdenEnviada, PrecioCliente } from '../../../../../store/actions/comerciosFood/ordenesActions'
import { SendMenssageAction } from '../../../store/actions/messageActions'
import { CreatePaymentIntent } from '../../../store/actions/comercioActions'
import { telFormat } from '../../../allFunctions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from "@material-ui/core/Card"
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Zoom from '@material-ui/core/Zoom'
import Grow from '@material-ui/core/Grow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
  props.timeout.enter = 500
  props.timeout.exit = 500
  return <Grow ref={ref} {...props} />
})

const theme = createMuiTheme({
  palette: {     
    secondary: {       
      main: '#e57373',       
      contrastText: '#fff',
    },
    primary: {       
      main: '#263238',       
      contrastText: '#fff',
    },
}})

const styles = theme => ({
    error: {
      backgroundColor: '#fff9c4',
      color: '#263238',
    },
    elements: {
      backgroundColor: '#e0f7fa',
      color: '#b2ebf2',

    },
    cardElement: {
      backgroundColor: '#e0f7fa',
      border: '1px solid #ced4da',
    },
    media: {
      // height: 80,
      // width: 140,
      height: 70,
      width: 70,
    },
    cancelar: {
      backgroundColor: '#e57373',
      '&:hover': {
          backgroundColor: "#e57373",
      },
      color: '#fff',
      textTransform: 'lowercase',
    },
    confirmacion: {
      color: '#4CA1A4'
    },
    styleContenedor: {
      [theme.breakpoints.up('sm')]: {
          margin: 'auto',
          minWidth: 900,
      } 
  },
})

const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#bdbdbd"
        },
        border: '1px solid #33c9dc'
        // backgroundColor: '#33c9dc'
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      },
    },
    hidePostalCode: true
}

const CheckoutForm = (props) => {

    const [idOrden, setIdOrden] = useState(uid())
    const [cardCompleted, setCardCompleted] = useState(false)

    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [clientSecret, setClientSecret] = useState(null)
    const stripe = useStripe()
    const elements = useElements()

    const [openCancelar, setOpenCancelarOrden] = useState(false)


    useEffect(() => {

      if(props.orden){
        CreatePayment()
      }
      
    }, [])

    useEffect(() => {

      if(cardCompleted){
        setDisabled(false)        
      }else{
        setDisabled(true)
      }

    }, [cardCompleted])

    const CreatePayment = async () => {

      const data = {
        id_orden: idOrden,
        id_comercio: 'q8OdHjmz6qdfaYW5JWeHlxQSbpx2',
        nombre: `${props.nombre} ${props.apellido}`,
        telefono: props.telefono,
        domicilio: props.domicilio,
        referencia: props.referencia,
        orden: props.orden,
        total: props.total,
        type: 'guuwiiWeb'
      }

      const crear = await props.CreatePaymentIntent(data)
      console.log('crear paymentintent', crear)
      if(crear.exito && crear.paymentIntent){
        
        setClientSecret(crear.paymentIntent)
      }

    }

    const handleChange = async (event) => {

      if(event.complete){
        setCardCompleted(true)
        
      }else{
        setCardCompleted(false)
        // setDisabled(true)
      }

      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      setError(event.error ? event.error.message : "")
    }

    const handleSubmit = async ev => {
      ev.preventDefault()
      setError(null)
      setProcessing(true)
      props.SendMenssageAction({option: 'inicio'})

      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          // billing_details: {
          //   address: {
          //     postal_code: postalCode,
          //   },
          //   name: name,
          //   email: email
          // }
        }
      })
      .then(resultado => {
          console.log('resultado', resultado)
          // console.log('resultado.status', resultado.status)

          if(resultado.paymentIntent.status === 'succeeded'){
            props.handlePaymentResult({id_orden: idOrden, message: 'succeeded'})
            
          }else if(resultado.paymentIntent.error){
            props.handlePaymentResult({message: resultado.error.message})
            
          }else{
            props.handlePaymentResult({message: 'El pago fue declinado'})
          }
          
        })
        .catch(error => {
          console.log(error);
          props.handlePaymentResult({message: 'El pago fue declinado'})
        })
        setProcessing(false)
        props.SendMenssageAction({option: 'reset'})

    }

    const calcularComisionPagoTarjeta = (totalVenta) => {

      let comisionTarjeta = (( parseFloat(totalVenta) * 0.036) + 3)
      const iva = parseFloat(comisionTarjeta) * 0.16
      comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
      return comisionTarjeta
    }

    // const handleCancelarOrden = async () => {

    //   props.SendMenssageAction({option: 'inicio'})
    //   setOpenCancelarOrden(false)
    //   const cancelar = await props.CancelarOrdenEnviada(ordenFood)
    //   props.SendMenssageAction(cancelar)
    // }

    //*render
    const { classes, setOpenPagoTarjeta, nombre, apellido, telefono } = props
    // if(!ordenFood || !props.FScostoPrecio) return null
    // console.log('clientSecret', clientSecret)

    // let total = 0
    // if(!ordenFood.servicioDomGratis && !ordenFood.recogerOrden){
    //     total = total + ordenFood.tiempo.costo
    // }

    // let total = parseInt(ordenFood.tiempo.costo)
    // `${process.env.PUBLIC_URL}/assets/img/icon-chrome.png`

    if(!clientSecret) return (
        <Box height={300} display='flex' justifyContent='center' alignItems='center'>
          <Box>
            <CircularProgress size={100} color='secondary' /> 
          </Box>
        </Box>
    )

    return (
        <Fragment>
          <Grid item className={classes.styleContenedor} xs={12}  >
          <Box height={300}>
            <Box px={1}  >
              <form id="payment-form" onSubmit={handleSubmit}>
                      {/* <Box display="flex" justifyContent="flex-end" className={classes.comercio}>
                          <Box >
                            <Button className={classes.cancelar} onClick={() => setOpenPagoTarjeta(false)} disabled={processing || succeeded} >
                              Cancelar
                            </Button>
                          </Box>
                      </Box> */}
                      <Box p={1} display='flex'>
                        <Box width={1/4}>
                            <CardMedia
                                className={classes.media}
                                image={`${process.env.PUBLIC_URL}/ulua-logo.jpg`}
                                />
                        </Box>
                        <Box  width={2/4} align='center'>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>Ulua Poke House</Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>Checkout</Typography>
                        </Box>
                        <Box  width={1/4} align='right'>
                            <Button className={classes.cancelar} onClick={() => setOpenPagoTarjeta(false)} disabled={processing} >
                              Cancelar
                            </Button>
                        </Box>
                    </Box>
                    <Box p={1}>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>{nombre} {apellido}</Typography>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>{telFormat(telefono)}</Typography>
                    </Box>
            
                      <Box m={1} p={1} className={classes.cardElement} >
                          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                      </Box>
                      <Box px={12} m={2}>
                          <Button type="submit" startIcon={processing ? <CircularProgress size={15} /> : null } variant="contained" color="secondary" fullWidth disableElevation disabled={processing || disabled}>
                              Pagar
                          </Button>
                      </Box>
                      <Box p={.5} mt={2} align="center" style={{display: error ? '' : 'none'}} >
                        <Box p={1} className={classes.error} >
                          <Typography>
                           {error}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider variant="middle" />
              </form>
            </Box>
            <Box>
              <Box mt={1}>
                  <Typography variant="h5" align={'center'} >
                      Pago con tarjeta de Credito / Debito
                  </Typography>
              </Box>
            </Box>
          </Box>

          <Dialog open={openCancelar} TransitionComponent={Transition} onClose={() => setOpenCancelarOrden(false)} maxWidth={'xs'} fullWidth >
              <Box textAlign="right" bgcolor={'primary.main'} py={1} ></Box>                
                  <Box align="center" p={1}>
                      <Typography variant="h5" className={classes.textColor} >
                      ¿Desea cancelar la orden?
                      </Typography>
                  </Box>
              <Box py={1} align={'center'}>
              <ThemeProvider theme={theme}>
                  <Button variant="contained" color="secondary" onClick={null} disableElevation>Cancelar</Button>
              </ThemeProvider>
              </Box> 
          </Dialog>

          {/* <Box mt={20} px={1} style={{display: succeeded ? '' : 'none' }} >
            <Box p={1} align="center" >
              <CircularProgress size={50} /> 
            </Box>
          </Box> */}
        </Grid>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        // storageDataReducer: state.storageDataReducer,
        orden: state.comercioReducer.orden,

        // FScostoPrecio: state.storageDataReducer.FScostoPrecio,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         CreatePaymentIntent: (datos) => dispatch(CreatePaymentIntent(datos)),
        //  PrecioCliente: (datos) => dispatch(PrecioCliente(datos)),
        //  CancelarOrdenEnviada: (datos) => dispatch(CancelarOrdenEnviada(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckoutForm))

CheckoutForm.propTypes = {
    
}