import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONETES
import { ResetMessage } from '../../store/actions/messageActions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    msgInfo: {
        backgroundColor: '#c8e6c9',
    },
    msgWarning: {
        backgroundColor: '#fff9c4',
    },
    msgError: {
        backgroundColor: '#ffcdd2',
    },
    cerrar: {
        backgroundColor: '#263238',
        color: '#fff'
    }
})

const Message = (props) => {

    const [openMessage, setOpenMessage] = useState(false)
    const [classMsg, setClassMsg] = useState()

    useEffect(() => {

        if(props.info || props.warning || props.error){
            setOpenMessage(true)
            if(props.info){
                setClassMsg(props.classes.msgInfo)
            }else if(props.warning){
                setClassMsg(props.classes.msgWarning)
            }else if(props.error){
                setClassMsg(props.classes.msgError)
            }

        }else{
            setOpenMessage(false)
        }

    }, [props.info, props.warning, props.error, props.msg])

    const handleCloseMsg = () => {
        props.ResetMessage()
    }

    //*render
    const {classes, msg } = props

    // console.log('classMsg', classMsg);
    return (
        <Fragment>
            <Dialog
            open={openMessage}
            TransitionComponent={Transition}
            maxWidth={'sm'}
            onClose={handleCloseMsg}
            fullWidth
            > 
                <Box p={.5}>
                    <Box className={classMsg} >
                        <Box display="flex" justifyContent="flex-end" className={classes.comercio}>
                            <Box >
                                <Paper elevation={0} className={classes.cerrar} onClick={handleCloseMsg}>
                                    <Box p={1}>
                                    <Typography variant="body1" align={'center'}>
                                        CERRAR
                                    </Typography>
                                    </Box>
                                </Paper>
                            </Box>                
                        </Box>
                        <Box p={3}>
                             
                            <Typography variant="h6" color="textSecondary" align={'center'} >
                                    <b>{msg}</b>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        info: state.messageReducer.info,
        warning: state.messageReducer.warning,
        error: state.messageReducer.error,
        msg: state.messageReducer.msg,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ResetMessage: (datos) => dispatch(ResetMessage(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Message))

Message.propTypes = {
    
}