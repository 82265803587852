import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

function CircularProgressWithLabel(props) {

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant='static' style={{position:'absolute', color: '#eff2f3'}} value={100} size={props.dispositivo === 'pc' ? 100 : 70} />
        <CircularProgress variant="static" value={props.value * 1.66} size={props.dispositivo === 'pc' ? 100 : 70} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >

            <Box>
                <Typography variant={props.dispositivo === 'pc' ? 'h3' : 'h6'} component="div" color="textSecondary">
                  {props.value}
                </Typography>                
                <Typography variant={props.dispositivo === 'pc' ? 'caption' : 'subtitle2'} component="div" color="textSecondary">
                    {props.text}
                </Typography>
            </Box>
        </Box>
      </Box>
    )
}

function CircularProgressHoursWithLabel(props) {

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant='static' style={{position:'absolute', color: '#eff2f3'}} value={100} size={props.dispositivo === 'pc' ? 100 : 70} />
        <CircularProgress variant="static" value={props.value * 4.16} size={props.dispositivo === 'pc' ? 100 : 70} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
        <Box>
            <Typography variant={props.dispositivo === 'pc' ? 'h3' : 'h6'} component="div" color="textSecondary">
                {props.value}
            </Typography>
            <Typography variant={props.dispositivo === 'pc' ? 'caption' : 'subtitle2'} component="div" color="textSecondary">
                {props.text}
            </Typography>
        </Box>
        </Box>
      </Box>
    );
  }

function CircularProgressDaysWithLabel(props) {

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant='static' style={{position:'absolute', color: '#eff2f3'}} value={100} size={props.dispositivo === 'pc' ? 100 : 70} />
        <CircularProgress variant="static" value={props.value * 0.27} size={props.dispositivo === 'pc' ? 100 : 70} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <Box>
                <Typography variant={props.dispositivo === 'pc' ? 'h3' : 'h6'} component="div" color="textSecondary">
                  {props.value}
                </Typography>
                <Typography variant={props.dispositivo === 'pc' ? 'caption' : 'subtitle2'} component="div" color="textSecondary">
                    {props.text}
                </Typography>
            </Box>
        </Box>
      </Box>
    );
  }

const TimerCountDown = (props) => {

    const [timeLeft, setTimeLeft] = useState(null)

    useEffect(() => {

        setTimeLeft(calculateTimeLeft())

    }, [props.tiempoActual])

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear()
        
        const difference = +new Date(`11/10/${year}`) - +new Date()

        let timeLeft
        if (difference > 0) {
            timeLeft = {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60)
            }
        }

        return timeLeft
    }
    
    //*render
    if(!timeLeft) return null
    return (
        <Fragment>
            <Box display='flex' justifyContent='center' >
                <Box ml={1}>
                    <CircularProgressDaysWithLabel value={timeLeft.days} text='Dias' dispositivo={props.dispositivo} />
                </Box>
                <Box ml={1}>
                    <CircularProgressHoursWithLabel value={timeLeft.hours} text='Horas' dispositivo={props.dispositivo} />
                </Box>
                <Box ml={1}>
                    <CircularProgressWithLabel value={timeLeft.minutes} text='Minutos' dispositivo={props.dispositivo} />
                </Box>
                <Box ml={1}>
                    <CircularProgressWithLabel value={timeLeft.seconds} text='Segundos' dispositivo={props.dispositivo} />
                </Box>
            </Box>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        tiempoActual: state.homeReducer.tiempoActual,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimerCountDown))

TimerCountDown.propTypes = {
    
}