//TYPES LOGIN
export const LOGIN_INICIA = 'LOGIN_INICIA'
export const LOGIN_EXITO = 'LOGIN_EXITO'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_EXITO = 'LOGOUT_EXITO'
export const RESET_STATE_LOGIN = 'RESET_STATE_LOGIN'

export const SET_TIEMPOACTUAL_EXITO = 'SET_TIEMPOACTUAL_EXITO'
export const GUARDAR_TIPODISPOSITIVO_EXITO = 'GUARDAR_TIPODISPOSITIVO_EXITO'
export const GUARDAR_DATACOMPATIBILIDAD_EXITO = 'GUARDAR_DATACOMPATIBILIDAD_EXITO'
export const SET_DECODED_EXITO = 'SET_DECODED_EXITO'

export const AGREGAR_PRODUCTO_EXITO = 'AGREGAR_PRODUCTO_EXITO'
export const SET_ALERTORDEN_EXITO = 'SET_ALERTORDEN_EXITO'

export const STORAGE_CATEGORIASMENU_EXITO = 'STORAGE_CATEGORIASMENU_EXITO'
export const STORAGE_ADICIONES_EXITO = 'STORAGE_ADICIONES_EXITO'
export const STORAGE_TELEFONO_EXITO = 'STORAGE_TELEFONO_EXITO'
export const STORAGE_ABIERTO_EXITO = 'STORAGE_ABIERTO_EXITO'
export const STORAGE_COSTOENVIO_EXITO = 'STORAGE_COSTOENVIO_EXITO'
export const STORAGE_CLIENTEDATA_EXITO = 'STORAGE_CLIENTEDATA_EXITO'

//*MESSAGES
export const ENVIAR_MESSAGE_INICIO = 'ENVIAR_MESSAGE_INICIO'
export const ENVIAR_MESSAGE_PREVE = 'ENVIAR_MESSAGE_PREVE'
export const ENVIAR_MESSAGE_EXITO = 'ENVIAR_MESSAGE_EXITO'
export const ENVIAR_MESSAGE_ERROR = 'ENVIAR_MESSAGE_ERROR'
export const RESET_STATE_ENVIARMESSAGE = 'RESET_STATE_ENVIARMESSAGE'
