import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'

//*COMPONENTES

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    textDomicilio: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            width: 0,
            minWidth: 900,
        } 
    },
})

const AlertMessages = (props) => {

    const [message, setMessage] = useState('')

    useEffect(() => {

        let newMessage = ''
        if(props.alertOrden.alert){
            newMessage = `(${props.alertOrden.numProductos}) - Total: $ ${parseFloat(props.alertOrden.total).toFixed(2)}`
        }
        
        setMessage(newMessage)

    }, [props.alertOrden])

    const handleMostrarPedido = () => {

        props.setOpenOrden(true)
    }

    //*render
    const { classes } = props

    return (
        <Fragment>

            {message !== '' ?
                <Slide in={true} direction="up">
                    <Box boxShadow={3} className={classes.textDomicilio}>
                        <Box display="flex" height={54} alignItems="center" justifyContent="center" >
                            <Box flexGrow={1}>
                                <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 18, textAlign: 'center'}}>
                                {message}
                                </Typography>
                            </Box>
                            <Box>
                                <Button startIcon={<ShoppingCartIcon />} variant="contained" color="secondary" style={{height: 54}} onClick={handleMostrarPedido}>
                                    Ver Orden
                                </Button>
                            </Box>
                        </Box>                       
                    </Box>
                </Slide>
            : null
            }
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        alertOrden: state.comercioReducer.alertOrden,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        //  MostrarSelectNavbar: (datos) => dispatch(MostrarSelectNavbar(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertMessages))