import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import uid from 'uid'

//*COMPONENTES
import Menu from '../comercioComponentes/Menu'
import Orden from '../comercioComponentes/Orden'
import PagoExitoso from '../comercioComponentes/pagoExitoso/PagoExitoso'
import { VerificarCompatibilidad, SetDecoded } from '../../store/actions/homeActions'
import { buscarDatosComercio } from '../../store/actions/comercioActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { getFechaHoy, telFormat, ordenarRight } from '../../allFunctions'

import { decoded } from './data'
import AlertMessages from '../comercioComponentes/AlertMessages'
import Message from '../layaout/Message'
import FullScreenLoading from '../layaout/FullScreenLoading'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'


import List from '@material-ui/core/List'


import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 900,
        marginTop: 0,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 120,
        // }
    },
    styleContenedorPC: {
        margin: 'auto',
        maxWidth: 900,
        marginTop: 0,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 120,
        // }
    },
    portada: {
        // maxheight: 0,
        // paddingTop: '76.25%', // 16:9
        height: '202px', //? 360*202 or 720*405 -- 1920*1080 
    },
    portadaPC: {
        // maxheight: 0,
        // paddingTop: '76.25%', // 16:9
        height: '425px', //? 360*202 or 720*405 -- 1920*1080 
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    media: {
        // height: 80,
        // width: 140,
        height: 70,
        width: 70,
      },
})


const WhatsComercio = ({
    VerificarCompatibilidad, 
    buscarDatosComercio, 
    SendMenssageAction, 
    SetDecoded, 
    FScategoriasMenu, 
    dataCompatibilidad, 
    telefonoComercio, 
    classes,
    abierto,
    dispositivo,
}) => {

    const [openOrden, setOpenOrden] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [categoriaMenu, setCategoriaMenu] = useState(null)

    const [openPagoExitoso, setOpenPagoExitoso] = useState(false)
    const [dataPagoExitoso, setDataPagoExitoso] = useState(null)
    
    useEffect(() => {
        VerificarCompatibilidad()
        // buscarDatosComercio({id_comercio: 'q8OdHjmz6qdfaYW5JWeHlxQSbpx2'})
        buscarDatosComercio({id_comercio: process.env.REACT_APP_UID})
        SendMenssageAction({option: 'inicio'})
        // inicializar()

        let data = decoded()

        data = ordenarRight(data)
        SetDecoded(data)
        
    }, [SendMenssageAction, SetDecoded, VerificarCompatibilidad, buscarDatosComercio])

    useEffect(() => {
        
        if(FScategoriasMenu){
        setCategoriaMenu(FScategoriasMenu)
        SendMenssageAction({option: 'reset'})
        }

    }, [FScategoriasMenu, SendMenssageAction])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenMaps = () => {
        
        if(dataCompatibilidad.osName === 'Android'){
            window.open("google.navigation:q=23.23774060802562, -106.43859373253285&mode=d" , '_system')
            return

        }else if(dataCompatibilidad.osName === 'iPhone'){
            window.open("https://maps.apple.com/?q=23.23774060802562, -106.43859373253285", '_system')
            return
        }
        
        window.open("http://www.google.com/maps/place/23.23774060802562, -106.43859373253285")
    }

    const handleOpenTelefono = () => {
        window.location.href = `tel:${telefonoComercio}`;
    }

    if(!categoriaMenu) return (
        <FullScreenLoading />
    )

    // const { classes, abierto, messageReducer } = props

    const hoy = getFechaHoy()
    
    let horario = 'Abre 1:00 p.m. - 09:45 p.m.'
    let cssHorario = {color: '#009688'}
    // if(hoy === 'Lunes'){
    //     horario = 'CERRADO'
    //     cssHorario = {color: '#c62828'}
    // }else 
    if(hoy === 'Sábado' || hoy === 'Domingo'){
        horario = 'Abre 1:00 p.m. - 8:40 p.m.'
    }

    return (
        <Fragment>
            <Grid item className={dispositivo === 'pc' ? classes.styleContenedorPC : classes.styleContenedor} >
                <Box>
                    <CardMedia
                        className={dispositivo === 'pc' ? classes.portadaPC : classes.portada}
                        // image={`${process.env.PUBLIC_URL}/assets/img/portada_img1.png`}
                        image={`https://firebasestorage.googleapis.com/v0/b/comerciosmx-mzt.appspot.com/o/comercios%2Fmazatlan%2Fuluapokehouse%2Fuluapokehouse-portada.jpg?alt=media&token=19aa0cd8-33d1-462e-a98b-d684c0adc18f`}
                    /> 
                </Box>
                
                <Box my={1} mx={2}>
                    <Card variant='outlined' onClick={null}>
                        <Box>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20, textAlign: 'center'}}>
                            Ulua Poke House
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontSize: 14, textAlign: 'center', fontWeight: 'bold', ...cssHorario}}>
                                {horario}
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, textAlign: 'center'}}>
                            Av Reforma 2007-B <br /> Suc. Plaza Laguna Business Center
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, textAlign: 'center'}}>
                            Mazatlán, Sinaloa
                            </Typography>
                            <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, color: '#2196f3', textAlign: 'center', cursor: 'pointer'}} onClick={handleOpenTelefono}>
                            {telFormat(telefonoComercio)}
                            </Typography>
                        </Box>
                    </Card>
                </Box>

                {!abierto ?
                <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: '#c62828'}}>
                    CERRADO
                </Typography>
                :
                <Box mt={2} mb={9}>
                    {categoriaMenu && categoriaMenu.map(categoria => {

                        return (
                            <Accordion expanded={expanded === categoria.id_categoria} onChange={handleChange(categoria.id_categoria)} key={categoria.id_categoria}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>{categoria.nombreCategoria}</Typography>
                                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor: '#fff'}}>
                                <Box width='100%'>
                                    <List>
                                    {categoria.menu.map(menu => {
                                        
                                        return (
                                            <Menu key={menu.id_menu} menu={menu} />
                                        )
                                    })}
                                    
                                    </List>
                                </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    
                </Box>
                }


                <Orden
                    openOrden={openOrden}
                    setOpenOrden={setOpenOrden}
                    setOpenPagoExitoso={setOpenPagoExitoso}
                    setDataPagoExitoso={setDataPagoExitoso}
                />

                <PagoExitoso
                    openPagoExitoso={openPagoExitoso}
                    setOpenPagoExitoso={setOpenPagoExitoso}
                    dataPagoExitoso={dataPagoExitoso}
                />

                <AlertMessages 
                    setOpenOrden={setOpenOrden}
                />

                <Message />            
                <FullScreenLoading />

            </Grid>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        dispositivo: state.homeReducer.dispositivo,
        dataCompatibilidad: state.homeReducer.dataCompatibilidad,
        FScategoriasMenu: state.comercioReducer.FScategoriasMenu,
        abierto: state.comercioReducer.abierto,
        telefonoComercio: state.comercioReducer.telefonoComercio,

        // configReducer: state.configReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         VerificarCompatibilidad: (datos) => dispatch(VerificarCompatibilidad(datos)),
         buscarDatosComercio: (datos) => dispatch(buscarDatosComercio(datos)),
         SetDecoded: (datos) => dispatch(SetDecoded(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WhatsComercio))

WhatsComercio.propTypes = {
    
}