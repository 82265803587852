import { 
    AGREGAR_PRODUCTO_EXITO,
    STORAGE_CATEGORIASMENU_EXITO,
    STORAGE_ADICIONES_EXITO,
    STORAGE_TELEFONO_EXITO,
    STORAGE_ABIERTO_EXITO,
    STORAGE_COSTOENVIO_EXITO,
    STORAGE_CLIENTEDATA_EXITO,
    SET_ALERTORDEN_EXITO
} from '../actionTypes'


const initState = {
    orden: [],
    FScategoriasMenu: null,
    FSmenuAdiciones: null,
    alertOrden: {
        alert: false,
        numProductos: '',
        total: ''
    },
    telefonoComercio: 0,
    abierto: true,
    costoEnvio: 0,
    clienteData: null
}

const comercioReducer = (state = initState, action) => {
    switch (action.type) {
        case AGREGAR_PRODUCTO_EXITO:
            return {
                ...state,
                orden: action.payload,
            }
        case STORAGE_CATEGORIASMENU_EXITO:
            return {
                ...state,
                FScategoriasMenu: action.payload,
            }
        case STORAGE_ADICIONES_EXITO:
            return {
                ...state,
                FSmenuAdiciones: action.payload,
            }
        case STORAGE_TELEFONO_EXITO:
            return {
                ...state,
                telefonoComercio: action.payload,
            }
        case STORAGE_ABIERTO_EXITO:
            return {
                ...state,
                abierto: action.payload,
            }
        case STORAGE_COSTOENVIO_EXITO:
            return {
                ...state,
                costoEnvio: action.payload,
            }
        case STORAGE_CLIENTEDATA_EXITO:
            return {
                ...state,
                clienteData: action.payload,
            }
        case SET_ALERTORDEN_EXITO:
            return {
                ...state,
                alertOrden: {
                    alert: action.alert,
                    numProductos: action.numProductos,
                    total: action.total
                },
            }
        default:
        return state
    }
}

export default comercioReducer