import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'

//*COMPONENTES
import CheckOpt from './CheckOpt'
import RadioOpt from './RadioOpt'
import { AgregarProducto } from '../../store/actions/comercioActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { onlyLettersNumbers } from '../../allFunctions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Drawer from '@material-ui/core/Drawer'
import TextField from '@material-ui/core/TextField'
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Slide from '@material-ui/core/Slide'


import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 900,
        } 
    },
    disabled: {
        color: '#e57373'
    },
    titleMenu: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    moreLess: {
        backgroundColor: '#e0e0e0',
        '&:hover': {
        backgroundColor: "#e0e0e0",
        },
    },
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 999,
        maxWidth: 900,
        // [theme.breakpoints.up('sm')]: {
        //     margin: 'auto',
        //     minWidth: 900,
        // }
    },
    title: {
        backgroundColor: '#bcaaa4'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 900,
        } 
    },
    totaMenu: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            width: 0,
            minWidth: 900,
        } 
    },
})


const MenuDetalle = (props) => {

    const [menu, setMenu] = useState(null)
    const [adicionError, setAdicionError] = useState('')
    const [openComentario, setOpenComentario] = useState(false)

    const refComentario = useRef()


    useEffect(() => {

        if(props.openCantidad){

            setMenu({
                id_menu: props.menu.id_menu,
                nombreMenu: props.menu.nombreMenu,
                descripcion: props.menu.descripcion,
                precioMenu: props.menu.precioMenu,
                cantidad: 1,
                comentario: '',
                id_adiciones: props.menu.id_adiciones,
                adiciones: props.menu.adiciones,
                imgSmall: props.menu.imgSmall,
                imgMedium: props.menu.imgMedium,
                agotado: false
            })

        }else{
            setMenu(null)
        }

    }, [props.menu, props.openCantidad])

    const add = () => {        
        setMenu({
            ...menu,
            cantidad: menu.cantidad + 1
        })        
    }

    const remove = () => {        
        if(menu.cantidad > 1){
            setMenu({
                ...menu,
                cantidad: menu.cantidad - 1
            })
        }        
    }    

    const handleChange = (e) => {
        
        if(onlyLettersNumbers(e.target.value)){

            setMenu({
                ...menu,
                [e.target.name] : e.target.value
            })
        }
    }

    const handleAddAdicion = (nuevaAdicion) => {

        const adiciones = JSON.parse(JSON.stringify(menu.adiciones)) 

        adiciones && adiciones.map(adicion => {

            if(adicion.id_adicion === nuevaAdicion.id_adicion){

                if(nuevaAdicion.type === 'check'){
                    adicion.menuAdiciones.push(nuevaAdicion)
                }else{
                    adicion.menuAdiciones = [nuevaAdicion]
                }
            }
        })

        setMenu({
            ...menu,
            adiciones : adiciones
        })

    }

    const handleRemoveAdcion = (nuevaAdicion) => {

        const adiciones = JSON.parse(JSON.stringify(menu.adiciones)) 
        adiciones && adiciones.map(adicion => {

            if(adicion.id_adicion === nuevaAdicion.id_adicion){

                adicion.menuAdiciones = adicion.menuAdiciones.filter(menuAdicion => menuAdicion.id_ingrediente !==  nuevaAdicion.id_ingrediente)
            }
        })

        setMenu({
            ...menu,
            adiciones : adiciones
        })

    }

    const handleAgregarProducto = async () => {

        let fallo = false
        let mensajeError = ''
        let countAdicion = 0

        const menuAdiciones = props.FSmenuAdiciones

        const selectedMenuAdiciones = []

        menu.id_adiciones && menu.id_adiciones.map(id_adicion => {
            menuAdiciones && menuAdiciones.map(adicion => {    
                if(id_adicion === adicion.id_adicion){
                    selectedMenuAdiciones.push(adicion)
                }
            })
        })

        let id_adicion = ''
        menu.adiciones && menu.adiciones.map(adicion => {
            
            if(!fallo){
                
                selectedMenuAdiciones && selectedMenuAdiciones.map(selectedAdicion => {
                    
                    if(adicion.id_adicion === selectedAdicion.id_adicion){
                        countAdicion = 0
                        
                        if(selectedAdicion.type === 'check'){
                            
                            let encontrado = false    
                            mensajeError = selectedAdicion.mensajeError
                            
                            adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                encontrado = true
                                countAdicion = countAdicion + 1
                            })

                            if((!encontrado && selectedAdicion.minimo !== 0) || countAdicion < selectedAdicion.minimo ){
                                fallo = true
                                id_adicion = adicion.id_adicion
                            }
    
                        }else if(selectedAdicion.type === 'radio'){

                            let encontrado = false    
                            mensajeError = selectedAdicion.mensajeError

                            adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                encontrado = true
                            })

                            if(!encontrado){
                                fallo = true
                                id_adicion = adicion.id_adicion
                            }
                        }
                    }
        
                })
            }

        })


        if(fallo){
            const anchor = document.getElementById(id_adicion)
            anchor.scrollIntoView({ block: "center", behavior: 'smooth' })
            // await props.SendMenssageAction({option: 'preve', message: mensajeError})
            setAdicionError(id_adicion)
            return
        }

        props.SendMenssageAction({option: 'inicio'})
        const agregar = await props.AgregarProducto(menu)
        if(agregar.exito){
            props.setOpenCantidad(false)
        }
        props.SendMenssageAction(agregar)
    }

    const handleOpenComentario = () => {

        setOpenComentario(true)
        setTimeout(() => {
            refComentario.current.focus()
        }, 200);
    }
    

    //*render
    if(!menu || !props.FSmenuAdiciones) return null
    const { classes, openCantidad, setOpenCantidad } = props

    let total = parseFloat(menu.precioMenu) * parseFloat(menu.cantidad)

    menu.adiciones && menu.adiciones.map(adicion => {
        
        adicion.menuAdiciones.map(ingrediente => {
            if(ingrediente.costo !== 0){
                total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(menu.cantidad))
            }
        })
    })

    const menuAdiciones = props.FSmenuAdiciones
    return (
        <Fragment>
             {/* <Drawer anchor={'top'} open={openCantidad} onClose={() => setOpenCantidad(false)} > */}
             <Dialog 
            open={openCantidad}
            // TransitionComponent={Transition}
            fullScreen
            >
                <Grid item className={classes.styleContenedor} xs={12}  >
                    <Box p={1} display="flex" alignItems="start" css={{ minHeight: 25, maxHeight: 50 }} className={classes.fixedUp} >
                        <Box mx={1} onClick={() => setOpenCantidad(false)}>
                            <Box display="flex" alignItems="center" css={{ minHeight: 25, maxHeight: 50 }}>
                                <Box>
                                    <ArrowBackIcon />
                                </Box>
                            </Box>
                        </Box>
                        <Box flexGrow={1} align={'center'} >
                            <Typography variant="h5" justify="center" >
                            {menu.nombreMenu}
                            </Typography>
                        </Box>
                        <Box mx={2} minWidth={10} >
                        </Box>
                    </Box>
                    <Box mt={7} mb={9} >
                    <Box>
                        <Card className={classes.card} elevation={0}>
                            <Box mt={1} py={0.5} display="flex" justifyContent="center" bgcolor="background.paper" >
                                <Box >
                                    <IconButton className={classes.moreLess} color="primary" size="medium" onClick={remove}>
                                        <RemoveIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                                <Box >
                                    <IconButton className={classes.margin} size="medium">
                                        {menu.cantidad}
                                    </IconButton>
                                </Box>
                                <Box >
                                    <IconButton className={classes.moreLess} size="medium" onClick={add}>
                                        <AddIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Box>
                            
                        </Card>
                    </Box>
                    
                    <Box mt={2}>
                    {menu.id_adiciones && menu.id_adiciones.map(id_adicion => {
                        let adicion
                        menuAdiciones && menuAdiciones.map(menuAdicion => {
                    
                            if(id_adicion == menuAdicion.id_adicion){
                                adicion = menuAdicion
                            }
                            return
                        })
                        if(adicion){
                            if(adicion.type === 'check'){
                                return(
                                    <CheckOpt
                                        key={adicion.id_adicion}
                                        menuAdicion={adicion}
                                        handleAddAdicion={handleAddAdicion}
                                        handleRemoveAdcion={handleRemoveAdcion}
                                        openCantidad={openCantidad}
                                        adicionError={adicionError}
                                    />
                                )
                            }else{
                                return(
                                    <RadioOpt
                                        key={adicion.id_adicion}
                                        menuAdicion={adicion}
                                        handleAddAdicion={handleAddAdicion}
                                        handleRemoveAdcion={handleRemoveAdcion}
                                        openCantidad={openCantidad}
                                        adicionError={adicionError}
                                    />
                                )
                            }
                        }else{
                            return null
                        }
                    })}
                    </Box>

                    <Box my={1} px={2}>
                        {menu.comentario.trim() !== '' ?
                            <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                ¿Algun comentario?
                            </Typography>
                        : null
                        }
                        <Card variant='outlined' onClick={handleOpenComentario}>
                            <Box px={2} display="flex" height={40} alignItems="center">
                                <Box>
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: menu.comentario.trim() === '' ? '#c62828' : '#000'}}>
                                        {menu.comentario.trim() === '' ? '¿Algun comentario?' : menu.comentario}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>

                    <Drawer anchor={'top'} open={openComentario} onClose={() => setOpenComentario(false)} >
                    <Grid item className={classes.styleContenedor} xs={12}  >
                    
                        <Box my={2} px={1}>
                            <TextField
                                inputRef={refComentario}
                                style={{fontFamily: 'Montserrat', fontSize: 14}}
                                label={
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                    ¿Algun comentario?
                                    </Typography>
                                }
                                inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                                name="comentario"
                                
                                value={menu.comentario}
                                onChange={handleChange}
                                color="secondary"
                                multiline
                                rowsMax="2"
                                fullWidth
                            />
                        </Box>

                        <Box px={2} mb={1} align='right'>
                            <Button variant="contained" color="secondary" style={{height: 54}} onClick={() => setOpenComentario(false)}>
                                Aceptar
                            </Button>
                        </Box>
                    </Grid>
                    </Drawer>
                </Box>

                <Slide in={!openComentario} direction="up">
                    <Box boxShadow={3} className={classes.totaMenu}>
                        <Box display="flex" height={54} alignItems="center" justifyContent="center" >
                            <Box flexGrow={1}>
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 18, textAlign: 'center'}}>
                                Total: ${parseFloat(total).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box>
                                <Button variant="contained" color="secondary" style={{height: 54}} onClick={handleAgregarProducto}>
                                    Agregar
                                </Button>
                            </Box>
                        </Box>                       
                    </Box>
                </Slide>
                
                </Grid>
                </Dialog>
            {/* </Drawer> */}
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        FSmenuAdiciones: state.comercioReducer.FSmenuAdiciones,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         AgregarProducto: (datos) => dispatch(AgregarProducto(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuDetalle))