import { 
    LOGIN_INICIA, 
    LOGIN_EXITO, 
    LOGIN_ERROR, 
    LOGOUT_EXITO, 
    RESET_STATE_LOGIN 
} from '../actionTypes'


const initState = {
    login: null,
    loginError: null,
    mensajeError: '',
    cargar: null,   
}

const loginReducer = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_INICIA:
            return {
                ...state,
                login: null,
                loginError: null,
                mensajeError: '',
                cargar: true 
            }               
        case LOGIN_EXITO:
            return {
                ...state,
                login: true,
                loginError: null,
                mensajeError: '',
                cargar: null   
            } 
        case LOGIN_ERROR:
            // console.log(action.payload)
            return{
                ...state,
                login: null,
                loginError: true,
                mensajeError: action.payload,
                cargar: null                
            }
        case LOGOUT_EXITO:
            return state
        case RESET_STATE_LOGIN:
            // console.log(action.payload)
            return{                
                login: null,
                loginError: null,
                mensajeError: '',
                cargar: null                  
            }
            
        default:
            return state
    }   
}



export default loginReducer