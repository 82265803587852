import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    menuDown: {
        backgroundColor: '#263238'
    },
    contactanos: {
        color: "#fff",
        cursor: 'pointer'
    },
    divider: {
        backgroundColor: '#00BCD4'
    },
    media: {
        height: 40,
        width: 40,
        // paddingTop: '56.25%', // 16:9
    },
    pointer: {
        cursor: 'pointer'
    }
})

const Footbar = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {


    }, [])

    const handleMailTo = () => {
        window.location.href = "mailto:contacto@guuwii.com"
    }

    const handleGoToFacebook = () => {
        // window.open('https://www.facebook.com/page?101329105033810', '_system')
        setTimeout(function () { window.location = 'https://www.facebook.com/guuwiimzt'}, 25);

        window.location = 'fb://page/101329105033810'
    }
    
    const handleGoToYoutube = () => {
        // window.open('https://www.youtube.com/guuwiimzt/', '_system')
        // window.open('fb://guuwiimzt', '_system')
        
    }
    const handleGoToInstagram = () => {
        window.open('https://www.instagram.com/guuwiimzt/', '_system')
        // setTimeout(function () { window.open('https://www.instagram.com/guuwiimzt/', '_system') }, 25);
        // window.location = "fb://profile/guuwiimzt"
        // window.location = 'https://www.instagram.com/guuwiimzt/'
        // window.location = 'instagram://user?username=guuwiimzt'
    }

    //*render
    const { classes } = props

    return (
        <Fragment>
            <Box>
                <Card>
                    <Box px={3} py={1} minHeight={150} className={classes.menuDown}>
                        <Box>
                            <Typography variant="h5" className={classes.contactanos}>
                            Guuwii:
                            </Typography>
                            <Box py={1}>
                                <Typography variant="body1" className={classes.contactanos}>
                                ¿Quienes somos?
                                </Typography>
                            </Box>
                            <Box py={1}>
                                <Typography variant="body1" className={classes.contactanos}>
                                Video blog
                                </Typography>
                            </Box>
                            <Box py={1}>
                                <Divider className={classes.divider} />
                            </Box>
                        </Box>

                        <Box py={1}>
                            <Typography variant="h5" className={classes.contactanos}>
                                Contactanos:
                            </Typography>
                            <Typography variant="body1" className={classes.contactanos} onClick={handleMailTo}>
                                contacto@guuwii.com
                            </Typography>
                            <Box py={1}>
                                <Divider className={classes.divider} />
                            </Box>
                        </Box>

                        <Box py={1}>
                            <Typography variant="h5" className={classes.contactanos}>
                                Redes Sociales:
                            </Typography>
                            <Box display="flex">
                                <Box px={1} className={classes.pointer} onClick={handleGoToFacebook}>
                                    <CardMedia 
                                        className={classes.media}
                                        image={`${process.env.PUBLIC_URL}/assets/img/facebook.jpg`}
                                    /> 
                                </Box>                               
                                <Box px={1} className={classes.pointer} onClick={handleGoToYoutube}>
                                    <CardMedia 
                                        className={classes.media}
                                        image={`${process.env.PUBLIC_URL}/assets/img/youtube.jpg`}
                                    /> 
                                </Box>                               
                                <Box px={1} className={classes.pointer} onClick={handleGoToInstagram}>
                                    <CardMedia 
                                        className={classes.media}
                                        image={`${process.env.PUBLIC_URL}/assets/img/instagram.jpg`}
                                    /> 
                                </Box>                               
                            </Box>

                        </Box>
                    </Box>
                </Card>
            </Box>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Footbar))

Footbar.propTypes = {
    
}