import React, { useState, useEffect, useRef, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import uid from 'uid'

//*COMPONENTES
import MenuOrden from './MenuOrden'
import { EnviarOrden, EliminarProducto, ValidarEnviarOrden, CheckCostoServDomicilio } from '../../store/actions/comercioActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { telFormat, fisrtCapitalLetter, onlyLettersNumbers, onlyLetters, esNumero } from '../../allFunctions'
// import MercadoPago from './MercadoPago'
import PaymentCard from './checkout/PaymentCard'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from "@material-ui/core/Typography"
import List from '@material-ui/core/List'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import DeleteIcon from '@material-ui/icons/Delete'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import TextField from '@material-ui/core/TextField'
import Drawer from '@material-ui/core/Drawer'
import Autocomplete from '@material-ui/lab/Autocomplete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 999,
        maxWidth: 900,

    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 900,
        } 
    },
})


const Orden = (props) => {
    
    const [valueRecoleccion, setValueRecoleccion] = useState('domicilio')
    const [valueMetodoPago, setValueMetodoPago] = useState('efectivo')

    // const [comisionTarjeta, setComisionTarjeta] = useState(0)

    const [nombre, setNombre] = useState('')
    const [apellido, setApellido] = useState('')
    const [telefono, setTelefono] = useState('')
    const [domicilio, setDomicilio] = useState('')
    const [colonia, setColonia] = useState('')
    const [idColonia, setIdColonia] = useState(null)
    const [referencia, setReferencia] = useState('')
    const [distancia, setDistancia] = useState('')
    const [openDataInfo, setOpenDataInfo] = useState(false)
    const [openDataDomicilio, setOpenDataDomicilio] = useState(false)
    const [openPagoTarjeta, setOpenPagoTarjeta] = useState(false)
    const [openPagaraCon, setOpenPagaraCon] = useState(false)
    const [pagaraCon, setPagaraCon] = useState('')
    
    const [disableEnviarOrden, setDisableEnviarOrden] = useState(true)

    const refNombre = useRef()
    const refApellido = useRef()
    const refTelefono = useRef()
    const refDomicilio = useRef()
    const refColonia = useRef()
    const refReferencia = useRef()
    const refPagaraCon = useRef()

    useEffect(() => {

        const cliente = props.clienteData

        if(cliente){

            if(cliente.hasOwnProperty('nombre') && typeof(cliente.nombre) === 'string'){
                setNombre(fisrtCapitalLetter(cliente.nombre))
            }
            if(cliente.hasOwnProperty('apellido') && typeof(cliente.apellido) === 'string'){
                setApellido(fisrtCapitalLetter(cliente.apellido))
            }
            if(cliente.hasOwnProperty('telefono') && typeof(cliente.telefono) === 'string'){
                setTelefono(cliente.telefono)
            }
            if(cliente.hasOwnProperty('domicilio') && typeof(cliente.domicilio) === 'string'){
                setDomicilio(fisrtCapitalLetter(cliente.domicilio))
            }
            if(cliente.hasOwnProperty('referencia') && typeof(cliente.referencia) === 'string'){
                setReferencia(fisrtCapitalLetter(cliente.referencia))
            }
            if(cliente.hasOwnProperty('idColonia') && typeof(cliente.idColonia) === 'string'){
                if(props.colonias && props.colonias.length !== 0){

                    const coloniaData = props.colonias.filter(colonia => colonia.id_colonia === cliente.idColonia)[0]
                    handleColoniaChange(coloniaData)                    
                }
                
            }

            // setDomicilio('')
        }

    }, [props.clienteData, props.colonias])
    
    useEffect(() => {

        if(valueRecoleccion === 'domicilio'){

            if(nombre !== '' && apellido !== '' && telefono.length === 10 && domicilio !== '' && colonia !== ''){
               setDisableEnviarOrden(false)
            }else{
                setDisableEnviarOrden(true)
            }
        }else{
            if(nombre !== '' && apellido !== '' && telefono.length === 10){
                setDisableEnviarOrden(false)
             }else{
                 setDisableEnviarOrden(true)
             }
        }


    }, [nombre, apellido, telefono, domicilio, valueRecoleccion, colonia])

    useEffect(() => {

        if(props.openOrden){

            setTimeout(() => {
                Swal.fire({
                    html: `<b>¡Servicio a Domicilio!</b><br/>
                    Se calculara conforme a distancia, al seleccionar la colonia a donde se enviara el servicio.`,
                    confirmButtonText: 'Cerrar'
                })
            }, 500);
        }

    }, [props.openOrden])

    const handleChangeRadioRecoleccion = (event) => {
        setValueRecoleccion(event.target.value);
    }

    const handleColoniaChange = async (data) => {

        const servicio = await props.CheckCostoServDomicilio(data)
        
        if(servicio.exito){
            setColonia(data.colonia)
            setIdColonia(data.id_colonia)
            setDistancia(servicio.distancia)
            
        }else{
            setColonia('')
            setIdColonia('')
        }
    }

    const handleChangeCalcularComisionTarjeta = () => {

        let costoDomicilio = 0
        if(valueRecoleccion === 'domicilio'){
            costoDomicilio = costoEnvio
        }
        let total = parseFloat(costoDomicilio)

        orden.map(producto => {
                        
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

            producto.adiciones && producto.adiciones.map(adicion => {

                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    return
                })
                return
            })
            return
        })

        let comisionTarjeta = (( parseFloat(total) * 0.036) + 3)
        const iva = parseFloat(comisionTarjeta) * 0.16
        comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
        
        // setComisionTarjeta(comisionTarjeta)
        return comisionTarjeta

    }

    const handleChangeRadioMetodoPago = (event) => {
        
        setValueMetodoPago(event.target.value);
        if(event.target.value === 'tarjeta'){
            Swal.fire({
                html: `<b>¡Pago con tarjeta!</b><br/>
                El pago con tarjeta genera un cobro adicional. La comisión dependera del monto total del servicio`,
                confirmButtonText: 'Cerrar'
            })
        }
        
    }

    const handleChange = (event) => {

        if(event.target.name === 'nombre'){

            if(onlyLetters(event.target.value)){
                setNombre(event.target.value)
            }
            return
        }
        if(event.target.name === 'apellido'){

            if(onlyLetters(event.target.value)){
                setApellido(event.target.value)
            }
            return
        }
        if(event.target.name === 'telefono' && event.target.value.toString().length <= 10){
            if(esNumero(event.target.value)){
                setTelefono(event.target.value)
            }
            return
        }
        if(event.target.name === 'domicilio'){

            if(onlyLettersNumbers(event.target.value)){
                setDomicilio(event.target.value)
            }

            return
        }
        if(event.target.name === 'referencia'){

            if(onlyLettersNumbers(event.target.value)){
                setReferencia(event.target.value)
            }
            return
        }
        if(event.target.name === 'pagaraCon'){
            if(esNumero(event.target.value)){
                setPagaraCon(event.target.value)
            }
            return
        }
    }

    const handleOpenDataInfo = (option) => {
        setOpenDataInfo(true)
        setTimeout(() => {
            if(option === 'nombre'){
                refNombre.current.focus()
            }else if(option === 'apellido'){
                refApellido.current.focus()
            }else if(option === 'telefono'){
                refTelefono.current.focus()
            }
            
        }, 200)
    }

    const handleOpenDomicilioInfo = (option) => {
        setOpenDataDomicilio(true)
        setTimeout(() => {
            if(option === 'domicilio'){
                refDomicilio.current.focus()
            }else if(option === 'referencia'){
                refReferencia.current.focus()
            }else if(option === 'colonia'){
                refColonia.current.focus()
            }
        }, 200)
    }

    const handleCheckMontoMinimo = () => {

        let total = 0
        {props.orden && props.orden.map(producto => {
                            
            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

            producto.adiciones && producto.adiciones.map(adicion => {

                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                    return
                })
                return
            })
            
        })}

        if(total >= 120){
            return true
        }else{
            return false
        }
        
    }

    const handleValidarEnviarOrden = async () => {

        if(valueRecoleccion === 'domicilio'){

            if(valueMetodoPago === 'efectivo'){

                props.SendMenssageAction({option: 'inicio'})
                const validar = await props.ValidarEnviarOrden()

                if(validar.exito){
                    setOpenPagaraCon(true)
                    setTimeout(() => {
                        refPagaraCon.current.focus()
        
                    }, 200)

                }else{

                    let texto = ''
                    if(validar.type === 'cerrado'){
                        texto = `El comercio esta cerrado`
                    }
                    if(validar.type === 'producto'){
                        texto = `El producto <br/> <b>${validar.productoAgotado}</b>  <br/> se ha agotado`
                    }
                    if(validar.type === 'ingrediente'){
                        texto = `El ingrediente <b>${validar.ingrediente}</b> <br/> del producto <br/> <b>${validar.producto}</b>  <br/> se ha agotado`
                    }
                    if(validar.type === 'monto'){
                        texto = `El monto minimo de compra <br/> es de <b>$ ${parseFloat(validar.monto).toFixed(2)}</b>`
                    }

                    Swal.fire({
                        title: '<strong>Lo sentimos</strong>',
                        icon: 'info',
                        html: texto,
                        confirmButtonText: 'Cerrar'
                    })
                }

                props.SendMenssageAction({option: 'reset'})

            }else{

                props.SendMenssageAction({option: 'inicio'})
                const validar = await props.ValidarEnviarOrden()

                if(validar.exito){
                    setOpenPagoTarjeta(true)

                }else{

                    let texto = ''
                    if(validar.type === 'cerrado'){
                        texto = `El comercio esta cerrado`
                    }
                    if(validar.type === 'producto'){
                        texto = `El producto <br/> <b>${validar.productoAgotado}</b>  <br/> se ha agotado`
                    }
                    if(validar.type === 'ingrediente'){
                        texto = `El ingrediente <b>${validar.ingrediente}</b> <br/> del producto <br/> <b>${validar.producto}</b>  <br/> se ha agotado`
                    }
                    if(validar.type === 'monto'){
                        texto = `El monto minimo de compra <br/> es de <b>$ ${parseFloat(validar.monto).toFixed(2)}</b>`
                    }

                    Swal.fire({
                        title: '<strong>Lo sentimos</strong>',
                        icon: 'info',
                        html: texto,
                        confirmButtonText: 'Cerrar'
                    })
                }

                props.SendMenssageAction({option: 'reset'})
            }
        }else{
            handleEnviarOrden(uid())
        }

    }

    const handleEnviarOrden = async (id_orden) => {

        props.SendMenssageAction({option: 'inicio'})
        const dataInfo = {
            version: '2.0',
            recoleccion: valueRecoleccion,
            nombre: nombre,
            apellido: apellido,
            telefono: telefono,
            domicilio: domicilio,
            colonia: colonia,
            idColonia: idColonia,
            referencia: referencia,
            metodoPago: valueMetodoPago,
            id_orden: id_orden,
            pagaraCon: pagaraCon
        }
        const enviar = await props.EnviarOrden(dataInfo)

        if(enviar.exito){
            const orden = `https://api.whatsapp.com/send?text=${enviar.texto}&phone=+52${props.telefonoComercio}`
            window.open(orden, '_blank');
        }
        props.SendMenssageAction(enviar)
        setOpenPagaraCon(false)

    }

    const handleEliminarProducto = async (data) => {
        props.SendMenssageAction({option: 'inicio'})
        const eliminar = await props.EliminarProducto(data)
        if(eliminar.vacio){
            props.setOpenOrden(false)
        }
        props.SendMenssageAction(eliminar)
    }

    const handlePaymentResult = (result) => {

        setOpenPagoTarjeta(false)

        if(result.message === 'succeeded'){
            const dataInfo = {
                recoleccion: valueRecoleccion,
                nombre: nombre,
                apellido: apellido,
                telefono: telefono,
                domicilio: domicilio,
                colonia: colonia,
                idColonia: idColonia,
                referencia: referencia,
                metodoPago: valueMetodoPago,
                id_orden: result.id_orden,
                pagaraCon: pagaraCon
            }
            
            
            Swal.fire({
                  title: 'Pago Exitoso!',
                  icon: 'success',
                  confirmButtonText: 'Enviar Orden!'
            })
            .then((response) => {
               
                
                handleEnviarOrden(result.id_orden)
                props.setOpenPagoExitoso(true)
                props.setDataPagoExitoso(dataInfo)
                props.setOpenOrden(false)
            })
        }else{
            Swal.fire({
                title: 'Pago Declinado!',
                icon: 'error',
                confirmButtonText: 'Cerrar'
            })
        }

    }

    //*render
    const { classes, openOrden, setOpenOrden, orden, costoEnvio} = props

    const recoleccion = [
        {
            id: 'domicilio',
            nombre: 'Enviar a domicilio'
        },
        {
            id: 'recoger',
            nombre: 'Pasar a recoger'
        }
    ]
    const metodoPago = [
        {
            id: 'efectivo',
            nombre: 'Pago en efectivo'
        },
        {
            id: 'tarjeta',
            nombre: 'Pago con tarjeta'
        }
    ]

    let costoDomicilio = 0
    let comisionTarjeta = 0
    let total = 0
    if(valueRecoleccion === 'domicilio'){
        costoDomicilio = costoEnvio
        total = parseFloat(total) + parseFloat(costoDomicilio)
    }

    if(valueMetodoPago === 'tarjeta' && valueRecoleccion === 'domicilio'){
        comisionTarjeta = handleChangeCalcularComisionTarjeta()
        total = parseFloat(total) + parseFloat(comisionTarjeta)
    }
    let count = 0
    return (
        <Fragment>
            <Dialog 
            open={openOrden}
            // TransitionComponent={Transition}
            fullScreen
            >
                <Grid item className={classes.styleContenedor} xs={12}  >

                <Box p={1} display="flex" alignItems="start" css={{ minHeight: 25, maxHeight: 50 }} className={classes.fixedUp} >       
                    <Box mx={1} onClick={() => setOpenOrden(false)}>
                        <Box display="flex" alignItems="center" css={{ minHeight: 25, maxHeight: 50 }}>
                            <Box>
                                <ArrowBackIcon />
                            </Box>
                        </Box>
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h5" justify="center" >
                        ORDEN
                        </Typography>
                    </Box>
                    <Box mx={2} minWidth={70} >
                        <Box display="flex" alignItems="center" css={{ minHeight: 25, maxHeight: 50 }}>
                            <Typography variant="h4" >
                                {/* $ {parseFloat(menu.precio).toFixed(2)} */}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box mt={5}>
                    <Box>

                        {orden && orden.map(producto => {
                            
                            count = count + 1
                            let totalProducto = parseFloat(producto.precioMenu) * parseFloat(producto.cantidad)
                            total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))

                            producto.adiciones && producto.adiciones.map(adicion => {

                                adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                    total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                    totalProducto = parseFloat(totalProducto) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                    return
                                })
                                return
                            })

                            return (
                                <MenuOrden
                                    key={producto.id_producto}
                                    producto={producto}
                                    total={totalProducto}
                                    ultimo={count === orden.length ? true : false}
                                    handleEliminarProducto={handleEliminarProducto}
                                />
                            )
                        })}
                    </Box>
                </Box>

                <Box px={2}>
                    {valueRecoleccion === 'domicilio' && costoDomicilio === 0 ?
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, textAlign: 'right'}}>
                           Servicio a domicilio: --
                        </Typography>
                    : valueRecoleccion === 'domicilio' && costoDomicilio !== 0 ?
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, textAlign: 'right'}}>
                            Serv. a domicilio: $ {parseFloat(costoDomicilio).toFixed(2)} <b style={{color: '#c62828', fontSize: 14}}>{distancia}</b>
                        </Typography>
                    :
                    null
                    }
                    {valueMetodoPago === 'tarjeta' && valueRecoleccion === 'domicilio' ?
                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, textAlign: 'right'}}>
                       {/* Comision pago con tarjeta: $ {parseFloat(comisionTarjeta).toFixed(2)} */}
                       Comision pago con tarjeta: $ {parseFloat(comisionTarjeta).toFixed(2)}
                    </Typography>
                    :
                    null
                    }
                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, textAlign: 'right'}} >
                    Total: $ {parseFloat(total).toFixed(2)}
                    </Typography>
                </Box>

                {/* <Box px={10} align='center'>
                    <Box>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 12, textAlign: 'center', color: '#2196f3'}}>
                            
                        </Typography>
                    </Box>
                </Box> */}

                <Box px={2} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>Metodo de recolección</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={valueRecoleccion} onChange={handleChangeRadioRecoleccion}>
                            {recoleccion.map(tipo => {
                                return(
                                    <FormControlLabel key={tipo.id} value={tipo.id} control={<Radio />}
                                    label={
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 16}} align='right' >
                                        {tipo.nombre}
                                        </Typography>
                                    } 
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
                {valueRecoleccion === 'domicilio' ?
                <Box px={2} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16}}>Metodo de pago</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={valueMetodoPago} onChange={handleChangeRadioMetodoPago}>
                            {metodoPago.map(metodo => {
                                return(
                                    <FormControlLabel key={metodo.id} value={metodo.id} control={<Radio />}
                                    label={
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 16}} align='right' >
                                        {metodo.nombre}
                                        </Typography>
                                    } 
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>
                :
                null
                }

                <Box>
                    <Box my={1} px={2}>
                        {nombre.trim() !== '' ?
                            <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                Nombre
                            </Typography>
                        : null
                        }
                        <Card variant='outlined' onClick={() => handleOpenDataInfo('nombre')}>
                            <Box px={2} display="flex" height={40} alignItems="center">
                                <Box>
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: nombre.trim() === '' ? '#c62828' : '#000'}}>
                                        {nombre.trim() === '' ? 'Nombre' : nombre}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box my={1} px={2}>
                        {apellido.trim() !== '' ?
                            <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                Apellido
                            </Typography>
                        : null
                        }
                        <Card variant='outlined' onClick={() => handleOpenDataInfo('apellido')}>
                            <Box px={2} display="flex" height={40} alignItems="center">
                                <Box>
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: apellido.trim() === '' ? '#c62828' : '#000'}}>
                                        {apellido.trim() === '' ? 'Apellido' : apellido}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box my={1} px={2}>
                        {telefono.trim() !== '' ?
                            <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                Telefono
                            </Typography>
                        : null
                        }
                        <Card variant='outlined' onClick={() => handleOpenDataInfo('telefono')}>
                            <Box px={2} display="flex" height={40} alignItems="center">
                                <Box>
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: telefono.trim() === '' ? '#c62828' : '#000'}}>
                                        {telefono.trim() === '' ? 'Telefono' : telFormat(telefono)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    {valueRecoleccion === 'domicilio' ?
                    <Box>
                        <Box my={1} px={2}>
                            {domicilio.trim() !== '' ?
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                    Domicilio
                                </Typography>
                            : null
                            }
                            <Card variant='outlined' onClick={() => handleOpenDomicilioInfo('domicilio')}>
                                <Box px={2} display="flex" height={40} alignItems="center">
                                    <Box>
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: domicilio.trim() === '' ? '#c62828' : '#000' }}>
                                        {domicilio.trim() === '' ? 'Domicilio: calle, número' : domicilio}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box my={1} px={2}>
                            {colonia.trim() !== '' ?
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                    Colonia
                                </Typography>
                            : null
                            }
                            <Card variant='outlined' onClick={() => handleOpenDomicilioInfo('colonia')}>
                                <Box px={2} display="flex" height={40} alignItems="center">
                                    <Box>
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: colonia.trim() === '' ? '#c62828' : '#000' }}>
                                        {colonia.trim() === '' ? 'Colonia' : colonia}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box my={1} px={2}>
                            {referencia.trim() !== '' ?
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 12, color: '#000'}}>
                                    Referencia
                                </Typography>
                            : null
                            }
                            <Card variant='outlined' onClick={() => handleOpenDomicilioInfo('referencia')}>
                                <Box px={2} display="flex" height={40} alignItems="center">
                                    <Box>
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 16, color: referencia.trim() === '' ? '#c62828' : '#000' }}>
                                        {referencia.trim() === '' ? 'Referencia' : referencia}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                    : null
                    }
                </Box>

                <Box px={2} mb={3}>
                    <Button startIcon={valueRecoleccion === 'recoger' ? <WhatsAppIcon fontSize='large' /> : valueMetodoPago === 'tarjeta' ? <CreditCardIcon fontSize='large' /> : <WhatsAppIcon fontSize='large' /> }  variant="contained" color="secondary" disabled={disableEnviarOrden} style={{height: 54}} fullWidth onClick={handleValidarEnviarOrden}>
                        {valueRecoleccion === 'recoger' ? 'Enviar orden' : valueMetodoPago === 'tarjeta' ? 'Realizar Pago' : 'Enviar orden'}
                    </Button>
                </Box>

                {/* <Box px={2} mb={1} align='right'>
                    <Button variant="contained" color="secondary" style={{height: 54}} onClick={() => setOpenPagoTarjeta(true)}>
                        Mercado Pago
                    </Button>
                </Box> */}

                <Drawer anchor={'top'} open={openDataInfo} onClose={() => setOpenDataInfo(false)} >
                <Grid item className={classes.styleContenedor} xs={12}  >

                    <Box my={1} px={2}>
                        <TextField
                            inputRef={refNombre}
                            label={
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                Nombre
                                </Typography>
                            }
                            inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                            name="nombre"
                            value={nombre}
                            onChange={handleChange}
                            type='text'
                            color="secondary"
                            variant="outlined"
                            fullWidth
                        />
                    </Box>
                    <Box my={1} px={2}>
                        <TextField
                            inputRef={refApellido}
                            label={
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                Apellido
                                </Typography>
                            }
                            inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                            name="apellido"
                            value={apellido}
                            onChange={handleChange}
                            type='text'
                            color="secondary"
                            variant="outlined"
                            fullWidth
                        />
                    </Box>
                    <Box my={1} px={2}>
                        <TextField
                            inputRef={refTelefono}
                            label={
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                Telefono
                                </Typography>
                            }
                            inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                            name="telefono"
                            value={telefono}
                            onChange={handleChange}
                            type='number'
                            color="secondary"
                            variant="outlined"
                            fullWidth
                        />
                    </Box>
                    <Box px={2} mb={1} align='right'>
                        <Button variant="contained" color="secondary" style={{height: 54}} onClick={() => setOpenDataInfo(false)}>
                            Aceptar
                        </Button>
                    </Box>
                    
                </Grid>
                </Drawer>

                <Drawer anchor={'top'} open={openDataDomicilio} onClose={() => setOpenDataDomicilio(false)} >
                <Grid item className={classes.styleContenedor} xs={12}  >

                    <Box style={{display: valueRecoleccion === 'domicilio' ? '' : 'none'}}>
                        <Box my={1} px={2}>
                            <TextField
                                inputRef={refDomicilio}
                                label={
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                    Domicilio
                                    </Typography>
                                }
                                inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                                placeholder='Calle, número'
                                name="domicilio"
                                value={domicilio}
                                onChange={handleChange}
                                type='text'
                                variant="outlined"
                                color="secondary"
                                multiline
                                rowsMax="2"
                                fullWidth
                            />
                        </Box>
                        <Box my={1} px={2}>
                            <Autocomplete
                                id="Colonia"
                                options={props.colonias}
                                getOptionLabel={(option) => option.colonia}
                                style={{ fontFamily: 'Montserrat', fontSize: 16 }}
                                fullWidth
                                renderInput={(params) => <TextField 
                                    inputRef={refColonia}
                                    {...params} 
                                    variant="outlined" 
                                    label="Colonia" 
                                    color="secondary" 
                                    size={16}
                                    style={{fontFamily: 'Montserrat', fontSize: 16}}
                                />}
                                onChange={(event, newValue) => {
                                    if(newValue){
                                        handleColoniaChange(newValue)
                                    }else{
                                        setColonia('')
                                        setIdColonia('')
                                    }
                                  }}
                            />
                        </Box>
                        <Box my={1} px={2}>
                            <TextField
                                inputRef={refReferencia}
                                label={
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                    Referencia
                                    </Typography>
                                }
                                inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                                name="referencia"
                                value={referencia}
                                onChange={handleChange}
                                type='text'
                                variant="outlined"
                                color="secondary"
                                multiline
                                rowsMax="2"
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box px={2} mb={1} align='right'>
                        <Button variant="contained" color="secondary" style={{height: 54}} onClick={() => setOpenDataDomicilio(false)}>
                            Aceptar
                        </Button>
                    </Box>
                    
                </Grid>
                </Drawer>

                <Dialog 
                    open={openPagaraCon}
                    TransitionComponent={Transition}
                    onClose={() => setOpenPagaraCon(false)}
                    maxWidth="xs"
                    fullWidth
                >

                    <Card variant='outlined'>
                        <Box>
                            <Box p={1} style={{backgroundColor: '#000', color: '#fff'}}>
                                <Typography style={{fontFamily: 'Montserrat', fontSize: 16, textAlign: 'center'}}>
                                    Monto con el que Pagara
                                </Typography>
                            </Box>
                            <Box my={1} px={2}>
                            <TextField
                                inputRef={refPagaraCon}
                                label={
                                    <Typography style={{fontFamily: 'Montserrat', fontSize: 16}}>
                                    Monto $
                                    </Typography>
                                }
                                inputProps={{ style: { fontFamily: 'Montserrat', fontSize: 16 } }}
                                name="pagaraCon"
                                value={pagaraCon}
                                onChange={handleChange}
                                type='number'
                                variant="outlined"
                                color="secondary"
                                fullWidth
                            />
                        </Box>
                        <Box px={2} mb={1} align='right'>
                            <Button variant="contained" color="secondary" style={{height: 54}} onClick={() => handleEnviarOrden(uid())}>
                                Aceptar
                            </Button>
                        </Box>
                        </Box>
                    </Card>

                </Dialog>
                

                {/* <Drawer anchor={'bottom'} open={openPagoTarjeta} onClose={() => setOpenPagoTarjeta(false)} >
                
                    <MercadoPago 
                    openPagoTarjeta={openPagoTarjeta}
                    setOpenPagoTarjeta={setOpenPagoTarjeta}
                    handleEnviarOrden={handleEnviarOrden}
                    />
                </Drawer> */}
                <Drawer anchor={'bottom'} open={openPagoTarjeta} onClose={() => setOpenPagoTarjeta(false)} >
                    <Grid item className={classes.styleContenedor} xs={12}  >
                
                    <PaymentCard 
                    total={total}
                    // openPagoTarjeta={openPagoTarjeta}
                    setOpenPagoTarjeta={setOpenPagoTarjeta}
                    nombre={nombre}
                    apellido={apellido}
                    telefono={telefono}
                    domicilio={domicilio}
                    referencia={referencia}
                    handlePaymentResult={handlePaymentResult}
                    // handleEnviarOrden={handleEnviarOrden}
                    />

                    </Grid>
                </Drawer>

            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        orden: state.comercioReducer.orden,
        telefonoComercio: state.comercioReducer.telefonoComercio,
        costoEnvio: state.comercioReducer.costoEnvio,
        clienteData: state.comercioReducer.clienteData,
        colonias: state.homeReducer.colonias,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         EnviarOrden: (datos) => dispatch(EnviarOrden(datos)),
         EliminarProducto: (datos) => dispatch(EliminarProducto(datos)),
         ValidarEnviarOrden: (datos) => dispatch(ValidarEnviarOrden(datos)),
         CheckCostoServDomicilio: (datos) => dispatch(CheckCostoServDomicilio(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Orden))

Orden.propTypes = {
    
}