import React, { useState, useEffect, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/Inbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import StoreIcon from '@material-ui/icons/Store'
import HomeIcon from '@material-ui/icons/Home';

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    list: {
        width: 250,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 200,
        } 
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
})

const Navbar = (props) => {

    const [openLeftMenu, setOpenLeftMenu] = useState(false)

    useEffect(() => {

    }, [])

    const handlePagePrincipal = () => {

        props.history.push('/')
        setOpenLeftMenu(false)
    }

    const handlePageComercio = () => {

        props.history.push('/comercio')
        setOpenLeftMenu(false)
    }

    //*render
    const { classes } = props

    if(props.location.pathname === '/uluapokehouse') return null
    return (
        <Fragment>
            <AppBar color="primary" elevation={0}>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} onClick={() => setOpenLeftMenu(true)}>
                        <MenuIcon color="secondary" />
                    </IconButton>
                        <Link to="/" className="linki"><Button color="inherit" >Guuwii</Button></Link>

                    {/* <Typography variant="h6" className={classes.title}>
                        Guuwii
                    </Typography> */}
                </Toolbar>
            </AppBar>

            <Drawer anchor={'left'} open={openLeftMenu} onClose={() => setOpenLeftMenu(false)}>
                <Box className={classes.list}>
                    <List>
                        <ListItem button onClick={handlePagePrincipal}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Guuwii" />
                        </ListItem>
                        <ListItem button onClick={handlePageComercio}>
                                <ListItemIcon>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Comercio" />
                        </ListItem>
                        {/* <ListItem button>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Drafts" />
                        </ListItem> */}
                    </List>
                </Box>
            </Drawer>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Navbar)))

Navbar.propTypes = {
    
}