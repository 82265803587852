import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Footbar from '../layaout/navegacion/Footbar'
import TimerCountDown from './TimerCountDown'
import { VerificarCompatibilidad, TiempoActual } from '../../store/actions/homeActions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import TabletIcon from '@material-ui/icons/Tablet'
import ComputerIcon from '@material-ui/icons/Computer'

import { withStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 900,
        marginTop: 35,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 120,
        // }
    },
    styleContenedorPC: {
        margin: 'auto',
        maxWidth: 900,
        marginTop: 45,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 120,
        // }
    },
    portada: {
        // maxheight: 0,
        // paddingTop: '76.25%', // 16:9
        height: '202px', //? 360*202 or 720*405 -- 1920*1080 
    },
    portadaPC: {
        // maxheight: 0,
        // paddingTop: '76.25%', // 16:9
        height: '425px', //? 360*202 or 720*405 -- 1920*1080 
    },
    disponibleEnAndroid: {
        // maxheight: 0,
        // paddingTop: '20.25%', // 16:9
        width: '200px',
        height: '50px', //? 360*202 or 720*405 -- 1920*1080 
    },
    disponibleEnIOS: {
        // maxheight: 0,
        // paddingTop: '20.25%', // 16:9
        width: '170px',
        height: '60px', //? 360*202 or 720*405 -- 1920*1080 
    },
    card: {
        backgroundColor: '#eff2f3',
        // height: 202
    },
    box1: {
        position: 'relative'
    },
    box2: {
        position: 'absolute',
        width: '80%',
        // marginTop: '10%',
        transform: 'translate(10%, -50%)',
    },
    back1: {
        backgroundColor: '#fff',
    },
    back2: {
        backgroundColor: '#00BCD4',
    },
    back3: {
        backgroundColor: '#eceff1'
    },
    avatar: {
        backgroundColor: '#263238'
        
    },
    menuDown: {
        backgroundColor: '#263238'

    },
    FabBack: {
        backgroundColor: '#1BD741',
        '&:hover': {
            backgroundColor: "#1BD741",
        },
        color: '#fff',
    },
    contactanos: {
        color: "#fff",
    },
    divider: {
        backgroundColor: '#fff'
    },
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff',
        right: '0px',
        width: '55px',
    },
    media: {
        height: 40,
        width: 40,
        // paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        backgroundColor: '#263238',
        border: `5px solid ${theme.palette.background.paper}`,

    },
    divider: {
        backgroundColor: '#00BCD4',
        height: 3,
    },
    empresario: {
        color: '#ffffff',

    },
    tiulo: {
        fontWeight: 'bold',
    }
})

const Principal = (props) => {

    const [Disposi, setDisposi] = useState()

    useEffect(() => {

        props.TiempoActual()
        props.VerificarCompatibilidad()

        const anchor = document.getElementById('principal')
        if (anchor) {
            anchor.scrollIntoView({ block: "start" });
        }

    }, [])

    useEffect(() => {

        if(props.dispositivo){

            if(props.dispositivo === 'pc'){
                
            }else{

            }
        }

    }, [props.dispositivo])

    const openGooglePlay = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.guuwiicliente"
    }

    const openAppleStore = () => {
        // window.location.href = "https://apps.apple.com/id/app/halojasa/id1492671277?l=id"
    }


    //*render
    if(!props.dispositivo || !props.dataCompatibilidad) return null

    const { classes, dataCompatibilidad } = props
    // console.log('props.dispositivo', props.dispositivo);
    // console.log('dataCompatibilidad', dataCompatibilidad);


    //*() => window.open("https://mazatlan.guuwii.com")

    return (
        <Fragment>
            <Box id="principal" height={1} border={1}>
            &nbsp; 
            </Box>
            <Grid item className={props.dispositivo === 'pc' ? classes.styleContenedorPC : classes.styleContenedor} >
            <Box >
                <CardMedia 
                    className={props.dispositivo === 'pc' ? classes.portadaPC : classes.portada}
                    image={`${process.env.PUBLIC_URL}/assets/img/portada_img1.png`}
                /> 
            </Box>

            <Box px={3} align="center">
                <Box p={1} mt={3}>
                	<Typography variant="h3" className={classes.tiulo}>
                	    Tu comida favorita

                	</Typography>
                	<Typography variant="h3" className={classes.tiulo}>
                	   en un solo lugar
                	</Typography>
                	<Typography variant="h3" className={classes.tiulo}>
                	   {/* {dataCompatibilidad.isFacebookApp ? 'facebook web' : 'La web'} */}
                	</Typography>
                </Box>

                {dataCompatibilidad.osName === 'Android' ?
                    <Box>
                        <CardMedia 
                            onClick={openGooglePlay}
                            className={classes.disponibleEnAndroid}
                            image={`${process.env.PUBLIC_URL}/assets/img/google-play-badge.png`}
                        /> 

                    </Box>
                : dataCompatibilidad.osName === 'iPhone' ?
                    <Box>
                        <Typography variant="h3" className={classes.tiulo}>
                        Muy pronto en Iphone
                        </Typography>
                        {/* <CardMedia 
                            onClick={openAppleStore}
                            className={classes.disponibleEnIOS}
                            image={`${process.env.PUBLIC_URL}/assets/img/App-IOS.png`}
                        />  */}
                    </Box>

                : null
                }

                {/* <Box py={1} align="center">
                    <Avatar src={`${process.env.PUBLIC_URL}/assets/img/telefono_img1.png`} className={classes.large} />
                </Box> */}

                {/* <Box p={2} >
                    <Card variant='outlined' >
                        <Box p={2}>
                            <TimerCountDown 
                            dispositivo={props.dispositivo}
                            />
                            <Typography variant="h4">
                                Aplicación cliente disponible 
                            </Typography>
                            <Typography variant="h4">
                                31 de Octubre 2020
                            </Typography>
                            {dataCompatibilidad.browserName === 'Chrome' || dataCompatibilidad.browserName === 'Safari' ? 
                            <Box my={2} align="center">
                                <Box maxWidth={250}>
                                    <Button endIcon={<PhoneAndroidIcon />} variant="contained" size="large" color="primary" disabled fullWidth disableElevation onClick={null}> 
                                    App Cliente
                                    </Button>
                                </Box>
                            </Box>
                            :
                            <Box my={2} align="center">
                                <Box maxWidth={250}>
                                    <Typography></Typography>
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Card>
                </Box> */}
            </Box>

            <Box px={6} mt={5}>
                <Divider className={classes.divider} />
            </Box>

            <Box mt={5}>
                <Box mt={4} className={classes.box1}>
                <Card variant="outlined" className={classes.back1}>
                    <Box className={classes.box2}>
                        <Card variant="outlined" className={classes.back2}>
                            <Box height={30} align="center">
                                <Typography variant="h5" className={classes.empresario}>
                                <b>¿Tienes un negocio?</b>
                                </Typography>
                            </Box>
                        </Card>
                    </Box>
                    <Box px={1} py={3}>
                        <Box align="center">
                            <Typography variant="h6">
                                Recibir pedidos de tus clientes es muy facil
                            </Typography>
                            <Typography variant="h6">
                                comunicate con nosotros
                            </Typography>
                        </Box>
                        {/* <Box px={3}>
                            <Typography>
                            <span>&#9679;</span> No necesitas de contratos.
                            </Typography>
                            <Typography>
                            <span>&#9679;</span> No necesitas cuotas mensuales.
                            </Typography>
                            <Typography>
                            <span>&#9679;</span> Manejamos la comisión mas baja del mercado.
                            </Typography>
                        </Box> */}
                        {/* <Box px={3} my={2} align="center">
                            <Typography>
                            Solo necesitas alguno de los siguientes dispositivos con conexión a internet
                            </Typography>
                        </Box> */}
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <Box align="center" width={1/3}>
                            <PhoneAndroidIcon fontSize="large" />
                            <Typography>
                                Celular
                            </Typography>
                        </Box>
                        <Box align="center" width={1/3}>
                            <TabletIcon fontSize="large" />
                            <Typography>
                                Tableta
                            </Typography>
                        </Box>
                        <Box align="center" width={1/3}>
                            <ComputerIcon fontSize="large" />
                            <Typography>
                                Pc
                            </Typography>
                        </Box>

                    </Box>

                    <Box my={2} align="center">
                        <Box maxWidth={250}>
                            <Link to="/comercio" className="linki">
                            <Button variant="contained" size="large" color="secondary" fullWidth disableElevation>
                                Mas información
                            </Button>
                            </Link>
                        </Box>
                    </Box>

                </Card>
                </Box>
            </Box>

            <Box height={50}>

            </Box>
            
            <Footbar />
        </Grid>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        dispositivo: state.homeReducer.dispositivo,
        dataCompatibilidad: state.homeReducer.dataCompatibilidad,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         VerificarCompatibilidad: (datos) => dispatch(VerificarCompatibilidad(datos)),
         TiempoActual: (datos) => dispatch(TiempoActual(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Principal))

Principal.propTypes = {
    
}