import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import MessengerCustomerChat from 'react-messenger-customer-chat'


//*COMPONENTES
import Footbar from '../layaout/navegacion/Footbar'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import StoreIcon from '@material-ui/icons/Store'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import CallIcon from '@material-ui/icons/Call'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import PinDropIcon from '@material-ui/icons/PinDrop'
import MessageIcon from '@material-ui/icons/Message'
import SettingsIcon from '@material-ui/icons/Settings'
import Badge from '@material-ui/core/Badge'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import Fab from '@material-ui/core/Fab'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 900,
        marginTop: 35,
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: 120,
        // }
    },
    portada: {
        // maxheight: 0,
        // paddingTop: '76.25%', // 16:9
        height: '202px', //? 360*202 or 720*405 -- 1920*1080 
    },
    card: {
        backgroundColor: '#eff2f3',
        // height: 202
    },
    box1: {
        position: 'relative'
    },
    box2: {
        position: 'absolute',
        width: '80%',
        // marginTop: '10%',
        transform: 'translate(10%, -50%)',
    },
    back1: {
        backgroundColor: '#fff',
    },
    back2: {
        backgroundColor: '#00BCD4',
    },
    back3: {
        backgroundColor: '#eceff1'
    },
    avatar: {
        backgroundColor: '#263238'
        
    },
    menuDown: {
        backgroundColor: '#263238'

    },
    FabBack: {
        backgroundColor: '#4FCE5D',
        '&:hover': {
            backgroundColor: "#4FCE5D",
        },
        color: '#fff',
        border: `3px solid ${theme.palette.background.paper}`,

    },
    contactanos: {
        color: "#fff",
    },
    divider: {
        backgroundColor: '#fff'
    },
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 80,
        width: '100%',
        color: '#ffffff',
        right: 11,
        width: '55px',
    },
    media: {
        height: 40,
        width: 40,
        // paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        backgroundColor: '#263238',
        border: `5px solid ${theme.palette.background.paper}`,

    },
    divider: {
        backgroundColor: '#00BCD4',
        height: 3,
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
})

const actions = [
    { icon: <WhatsAppIcon />, name: 'WhatsApp' },
    { icon: <MessageIcon />, name: 'Messenger' },
]

const Comercio = (props) => {

    const [openSpeedDial, setOpenSpeedDial] = useState(false)
    const [minimized, setMinimized] = useState(true)
    const [showChatDialog, setShowChatDialog] = useState(false)

    useEffect(() => {
        setMinimized(false)
        const anchor = document.getElementById('comercio')
        if (anchor) {
            anchor.scrollIntoView({ block: "start" });
        }
    }, [])


    const handleOpen = () => {
        setOpenSpeedDial(true)

    }

    const handleClose = () => {
        setOpenSpeedDial(false)
    }

    const handleActionWhatsApp = () => {

        const mensaje = 'hola!, en que te puedo ayudar?'
        window.location.assign(`https://api.whatsapp.com/send?phone=526692729278&text${mensaje}`)
        setOpenSpeedDial(false)
    }

    const handleActionMessanger = () => {

        setShowChatDialog(true)
        setOpenSpeedDial(false)

    }

    //*classes
    const { classes } = props
    return (
        <Fragment>
            <Box id="comercio" height={1} border={1}>
            &nbsp; 
            </Box>
        <Grid item className={classes.styleContenedor} >

            <Box px={3} py={1} align="center" >
                <Box p={1}>
                	<Typography variant="h4">
                	    Recibe los pedidos de tus clientes desde tu dispositivo
                	</Typography>
                </Box>
                <Avatar src={`${process.env.PUBLIC_URL}/assets/img/telefono_img1.png`} className={classes.large} />
            </Box>

            <Box mt={8} className={classes.box1}>
                <Card variant="outlined" className={classes.back1}>
                    <Box minHeight={100}>
                        <Box className={classes.box2}>
                            <Card variant="outlined" className={classes.back2}>
                                <Box height={30} align="center">
                                    <Typography variant="h5">
                                    ¿Que te ofrecemos?
                                    </Typography>
                                </Box>
                            </Card>
                        </Box>


                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <StoreIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                        <b>Comercio App</b>
                                    </Typography>
                                    <Typography variant="body1" align="justify">
                                        Instalación de la aplicación para recibir pedidos de tus clientes por internet.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <MotorcycleIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                    <b>Servicio de Reparto</b>
                                    </Typography>
                                    <Typography variant="body1" align="justify">
                                        Llevamos tu comida a cualquier punto de la ciudad.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <CallIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                    <b>Soporte</b>
                                    </Typography>
                                    <Typography variant="body1" align="justify">
                                        Asistencia telefonica en caso de requerir ayuda.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                    </Box>
                </Card>
            </Box>

            <Box mt={4} className={classes.box1}>
                <Card variant="outlined" className={classes.back1}>
                    <Box minHeight={100}>
                        <Box className={classes.box2}>
                            <Card variant="outlined" className={classes.back2}>
                                <Box height={30} align="center">
                                    <Typography variant="h5">
                                    ¿Que te ofrece la aplicación?
                                    </Typography>
                                </Box>
                            </Card>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <RestaurantMenuIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                    <b>Menu</b>
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Registra todos los platillos deseados (Nombre, Precio, Descripción).
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Edita o elimina platillos.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Habilita o Deshabilita platillos.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <PinDropIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                    <b>Geolocalización</b>
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Muestra la ubicación del domicilio del cliente.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Traza la ruta por la cual se debe dirigir el repartidor.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <MessageIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                        Notificaciones
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Recibe notificaciones al recibir una orden.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Recibe notificaciones al finalizar un proceso importante.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Box pt={3}>
                            <Box display="flex">
                                <Box p={1}>
                                <Avatar className={classes.avatar}>
                                    <SettingsIcon />
                                </Avatar>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="h6">
                                    <b>Configuración</b>
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Abre y cierra tu comercio virtual.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Registra y Modifica tu horario de servicio.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Crea cuentas de usuarios.
                                    </Typography>
                                    <Typography variant="body1">
                                    <span>&#9679;</span> Reportes de ordenes recibidas.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                    </Box>
                </Card>
            </Box>


            <Box>
                <Box m={2} className={classes.fixedDown}>
                    {/* <SpeedDial
                        ariaLabel="Contactanos"
                        className={classes.speedDial}
                        icon={<WhatsAppIcon  />}
                        openIcon={<WhatsAppIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={openSpeedDial}
                    >
    
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.name === 'WhatsApp' ? handleActionWhatsApp : handleActionMessanger}
                            />
                        ))}
    
                    </SpeedDial> */}

                    <Fab className={classes.FabBack} onClick={handleActionWhatsApp}>
                        <WhatsAppIcon />
                    </Fab>
                </Box>
            </Box>

            {/* <MessengerCustomerChat
                pageId={process.env.REACT_APP_PAGEID}
                appId={process.env.REACT_APP_APPID}
                // shouldShowDialog={showChatDialog}
                // minimized={minimized}
                // htmlRef="<REF_STRING>"
            /> */}

            <Box mt={3}>
                <Footbar />
            </Box>



                













        </Grid>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Comercio))

Comercio.propTypes = {
    
}