import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item  } from 'react-photoswipe-gallery'

//*COMPONENTES
import MenuDetalle from './MenuDetalle'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const Menu = (props) => {

    const [openCantidad, setOpenCantidad] = useState(false)

    useEffect(() => {

    }, [])

    //*render
    const { menu } = props
    
    return (
        <Fragment>
            <Divider />
            <Box display='flex'>
                <Box flexGrow={1}>
                    <ListItem button disabled={menu.disabled} onClick={() => setOpenCantidad(true)} style={{border: 1, backgroundColor: '#fff', borderRadius: '10px', marginTop: '5px'}}>
                        <Box>
                            <Box>
                                <ListItemText primary={
                                    <Box>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}> 
                                            {menu.nombreMenu}
                                        </Typography>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14, color: '#b71c1c'}}>
                                        {menu.disabled ? 'AGOTADO' : ''}
                                        </Typography>
                                    </Box>
                                    }
                                    secondary={
                                        <Typography style={{fontFamily: 'Montserrat', fontSize: 12, textAlign: 'justify'}}>
                                        {menu.descripcion} <br /><b>$ {parseFloat(menu.precioMenu).toFixed(2)}</b>
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Box>
                    </ListItem>
                </Box>
                <Box mt={3} align='right'>
                    <Gallery shareButton={false} fullscreenButton={false}>
                        <Item
                        original={menu.imgMedium}
                        thumbnail={menu.imgSmall}
                        width="720"
                        height="480"
                        >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={menu.imgSmall} />
                        )}
                        </Item>
                    </Gallery>
                </Box>
            </Box>
            
            <MenuDetalle 
            menu={menu} 
            openCantidad={openCantidad}
            setOpenCantidad={setOpenCantidad}
            />
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu))